import { useState, useEffect } from 'react';
import '../styles/dk.scss';
import dimg1 from '../assets/dimg1.jpg';
import dimg2 from '../assets/dimg2.jpg';
import dimg3 from '../assets/dimg3.jpg';
import dimg4 from '../assets/dimg4.jpg';
import dimg5 from '../assets/dimg5.jpg';
import dimg6 from '../assets/dimg6.jpg';
import dimg7 from '../assets/dimg7.jpg';
import dimg8 from '../assets/dimg8.jpg';
import dimg9 from '../assets/dimg9.jpg';
import dimg10 from '../assets/dimg10.jpg';
import dimg11 from '../assets/dimg11.jpg';
import BackButton from '../snippets/BackButton';
import Loading from '../snippets/Loading';
import Intro from '../snippets/Intro';

function DongKyun() {
    let imageArr = [dimg1, dimg2, dimg3, dimg4, dimg5, dimg6, dimg7, dimg8, dimg9, dimg10, dimg11];
    const [selectedIndex, setSelectedIndex] = useState('');
    const [displaySelectedImg, setDisplaySelectedImg] = useState('none');
    const [ratio, setRatio] = useState(window.innerWidth*0.33);
    useEffect(() => {
      if(window.innerWidth<768){
        setRatio(window.innerHeight*.75*.7);
      }
    }, [ratio]);

    const imageMagnifier = (event : any) => {
      if(window.innerWidth>=768){
        var indexOfImg = Array.from(event.target.parentNode.parentNode.children).indexOf(event.target.parentNode);
        setSelectedIndex('url('+imageArr[indexOfImg]+')');
        setDisplaySelectedImg('inherit');
      }
    }
    const imageMinifier = (event : any) => {
      if(window.innerWidth>=768){
        setDisplaySelectedImg('none');
      }
    }

    return (
      <div className="dk" style={{height:window.innerHeight+'px'}}>
        <BackButton />
        <Loading />
        <div className="dk__container h-full flex flex-auto flex-row flex-nowrap space-x-4 items-center align-middle">
          <div className="dk__item " onClick={(e) => imageMagnifier(e)}>
          <div style={{width:ratio + 'px'}}>&nbsp;</div>
          </div>
          <div className="dk__item " onClick={(e) => imageMagnifier(e)}>
          <div style={{width:ratio + 'px'}}>&nbsp;</div>
          </div>
          <div className="dk__item " onClick={(e) => imageMagnifier(e)}>
          <div style={{width:ratio + 'px'}}>&nbsp;</div>
          </div>
          <div className="dk__item" onClick={(e) => imageMagnifier(e)}>
          <div style={{width:ratio + 'px'}}>&nbsp;</div>
          </div>
          <div className="dk__item" onClick={(e) => imageMagnifier(e)}>
          <div style={{width:ratio + 'px'}}>&nbsp;</div>
          </div>
          <div className="dk__item" onClick={(e) => imageMagnifier(e)}>
          <div style={{width:ratio + 'px'}}>&nbsp;</div>
          </div>
          <div className="dk__item" onClick={(e) => imageMagnifier(e)}>
          <div style={{width:ratio + 'px'}}>&nbsp;</div>
          </div>
          <div className="dk__item" onClick={(e) => imageMagnifier(e)}>
          <div style={{width:ratio + 'px'}}>&nbsp;</div>
          </div>
          <div className="dk__item" onClick={(e) => imageMagnifier(e)}>
          <div style={{width:ratio + 'px'}}>&nbsp;</div>
          </div>
          <div className="dk__item" onClick={(e) => imageMagnifier(e)}>
          <div style={{width:ratio + 'px'}}>&nbsp;</div>
          </div>
          <div className="dk__item" onClick={(e) => imageMagnifier(e)}>
          <div style={{width:ratio + 'px'}}>&nbsp;</div>
          </div>
      </div>
      <Intro
        name= "박동균"
        title= "- A Generic View"
        artistIntro= {`
          박동균은 서울을 기반으로 활동하는 이미지 프로듀서이다. 그는 특정한 기능을 갖는 기술적 사물을 이미지로 번역하는 작업을 한다. 그의 작업은 사물에서 발견되는 다양한 디테일과 사물의 형태발생(morphogenesis)에 초점을 맞추고 있다. 건축이론에서 말하는 “형태는 기능을 따른다”라는 표어에 착안해 그는 사물의 형태와 기능이 맺고 있는 필연적인 연결에 대해서 탐구한다. 그는 인공물을 인간의 기술이 도래한 이래 현재의 세계를 이해할 수 있는 하나의 솔루션으로 바라본다. 형태는 생각보다 많은 것에 대해 말한다. 
          2019년 3월에 아카이브봄에서 첫 번째 개인전 «UU:Universal Universe»를 열었다. 이어서 2021년 10월 소쇼에서 두 번째 개인전 «B777»을 선보였다. «2017 미래작가상»과 «A New Gaze 3»(2019)를 수상했다.
          현재 이미지 프로덕션 프로토콜 VDK Generic Images를 운용 중이다.
        `}
        projectDescription= {`
          ‘제네릭 뷰’는 작가가 인류세에서 마주하는 풍경을 캠페인 이미지의 형식으로 보여주는 작업이다. 이 작업에서 작가는 서울의 땅 위로 그어진 동일한 크기의 그리드 위에서 만들어지는 일련의 형태학적 패턴에 주목하는데, 이 패턴은 다양한 풍경의 그룹 안에서 손쉽게 찾아낼 수 있는 일반적인 패턴을 말한다. ‘제네릭 뷰’는 인류세의 시각성을 서울의 풍경으로부터 추출해내고 이것을 하나의 원근법으로 분류한다. 여기에 제시된 텍스트와 이미지는 서로를 보완하지 않는다. 오히려 이 둘은 자신이 속한 서로 다른 차원을 교차시키고 층층이 겹쳐나가면서 하나의 지평선 위로 수렴된다.                </div>
        `}
      >
      </Intro>
      <div className="dk__photo-detail" onClick={(e) => imageMinifier(e)}  style={{display:displaySelectedImg}}>
        <div className='dk__photo-detail-image' style={{backgroundImage:selectedIndex}}>
        </div>
      </div>
    </div>
  );
}

export default DongKyun;
