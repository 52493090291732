import { Link } from 'react-router-dom';
import { ReactComponent as Back } from '../assets/back.svg';
import { ReactComponent as BackHovered } from '../assets/buttons-06.svg';

function BackButton() {
  return (
    <Link to="/" className="back" data-activatable-hover-toggler="back">
      <Back className="about-not-hovered" data-activatable-toggleable-item="back" />
      <BackHovered className="about-hovered" data-activatable-toggleable-item="back" />
    </Link>
  )
}

export default BackButton;