import { useEffect } from 'react';
import { ReactComponent as About } from '../assets/ur.svg';
import { ReactComponent as AboutHovered } from '../assets/buttons-07.svg';
import Activatable from "../scripts/activatable";
import ActivatableSingleton from "../scripts/activatableSingleton";

export const Modules2 = [
  Activatable, 
  ActivatableSingleton
];

function Button(props : any) {
  useEffect(() => {
    Modules2.forEach((module) => module.init());
  }, []);
  return (
    <button
      className="about open-about"
      onClick={props.openAbout} 
      data-activatable-hover-toggler="butt"
    >
      <About className="about-not-hovered" data-activatable-toggleable-item="butt" />
      <AboutHovered className="about-hovered" data-activatable-toggleable-item="butt" />
    </button>
  )
}

export default Button;