import BackButton from '../snippets/BackButton';
import Loading from '../snippets/Loading';
import Intro from '../snippets/Intro';

function Abhijan() {
  return (
    <div className="abhijan" style={{height:window.innerHeight+'px'}}>
      <BackButton />
      <Loading />
      <Intro
				name= "Abhijan Toto+Bela"
				title= "- Detritus Of A Queer Conversation 퀴어 대화의 잔해"
				artistIntro= {`
          아비잔 토토는 생태철학, 학제간 연구, 노동과 하부구조에 관심을 두고 독일 베를린, 태국 방콕과 서울에서 활동하는 큐레이터이자 저술가이다. 2018년부터 푸지타 구하와 함께 다양한 플랫폼에 걸쳐 남아시아·동남아시아 삼림지역의 자연문화 주변을 리서치 및 상호 학습하는 프로젝트 포레스트 커리큘럼을 설립하였다.
          <br /><br />
          벨라는 클럽 음악과 실험적 전자음악의 접점에 선 음악가이자 디제이이다. 최근 퀴어니스와 축원의 의식의 관계에 관심을 두고 사운드 및 퍼포먼스 작업을 펼치고 있다. 서울과 도쿄, 상하이 등지에서 활발히 디제잉 활동을 전개하였으며, 현재 서울의 이벤트 시리즈 비애클럽의 공동 운영진이다.
        `}
				projectDescription= {`
          ‘Detritus Of A Queer Conversation 퀴어 대화의 잔해’는 서울 미감 프로젝트의 일환으로 서울과 퀴어니스의 관계에 대해 사유하기 위해 초대된 아비잔 토토와 벨라의 대화로부터 생겨나 인터넷 상에 머무르게 된 일시적인 것들—메모들, 인스타그램 열폭글, 서신 등—을 모은 것이다. 이 모음은 2021년 말부터 2022년 초까지 이뤄진 몇 차례의 교신에서 생성된 텍스트와 이미지를 드러내고 있지만, 원래의 교신들 자체를 보여주진 않는다. 그보다도, 대화들의 배경이 되는 맥락과 그로부터 비롯된 아이디어와 제스처들을 내민다. 대화를 이끈 주제들은 숨기기—보여주지 않기—에 대한 생각과 서울의 맥락에서 퀴어 개인이 시선의 영역을 헤쳐나간다는 것의 의미였으나, 본 기고문은 그 잔여물, 과잉, 심지어는 과정에서 버려진 것에만 관여하며 퀴어 대화의 공간임을 표지할 뿐인 흔적같은 존재감을 구축한다.
          <br />
          'Detritus Of A Queer Conversation' is a collection of net-based ephemera - notes, instagram rants, correspondence - that emerged out of a conversation between Abhijan Toto and bela as a response to being invited to think about queerness and its relationship to the city of Seoul as part of the Seoul Aesthetic Project. They represent texts and images that emerged out of a series of exchanges that took place between late 2021 to early 2022. They do not however represent these exchanges. Rather, they provide a kind of context within which the conversations took place - as well as ideas and gestures that emerged through them. Central to this conversation has been the idea of obscurity, and what it means to navigate regimes of vision as a queer person within a Seoul context - and thus the contribution concerns itself only with what is left over, an excess, or even what might be discarded, and builds its lingering presence that marks the space of a queer conversation.
        `}
			>
			</Intro>
    </div>
  );
}

export default Abhijan;