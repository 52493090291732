import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Back } from '../assets/back.svg';
import '../styles/keys.scss';

function Keys() {
  const [file, setFile] = useState("/assets/keys/key_no_2.pdf");
  const [numPages, setNumPages] = useState(null);
    
  function onDocumentLoadSuccess({numPages}:any) {
    setNumPages(numPages);
  }

  return (
    <div className="keys" style={{height:window.innerHeight+'px'}}>
      <Link to="/" className="back">
        <Back />
      </Link>
      <audio autoPlay controls className='keys__audio'>
        <source src="/assets/keys/key_no_1.wav" type="audio/wav" />
        Your browser does not support the audio element.
      </audio>
      <div className='keys__iframe'>
        <img className='keys__iframe-img' src="/assets/keys/key_no_2.png"></img>
      </div>
    </div>
  );
}

export default Keys;