import Game from "reactified-phaser/Game";
import {observer} from "mobx-react"
import '../styles/hwi.scss';
import { ReactComponent as Arrow } from '../assets/arrow-05.svg';
import { ReactComponent as HWILogo } from '../assets/hwi-logo.svg';
import Intro from '../snippets/Intro';
import BackButton from '../snippets/BackButton';
import Loading from '../snippets/Loading';
  
var light: Phaser.GameObjects.Light;
var spotlight: Phaser.GameObjects.Light;
var offsets: number[] = [];
var player: Phaser.Physics.Arcade.Sprite | undefined;
var layer: Phaser.Tilemaps.TilemapLayer;
let cursors: Phaser.Types.Input.Keyboard.CursorKeys | undefined;
var numbers: number[] = [];
var selectedLinks: string[] = [];
var links = [
    "https://youtu.be/TLw-beqfRYs",
    "https://en.wikipedia.org/wiki/Atrium_(architecture)",
    "https://www.koreascience.or.kr/article/JAKO200830360546052.pdf",
    "https://www.edaily.co.kr/news/read?newsId=02892966606252920&mediaCodeNo=257",
    "https://www.lottepnd.com/tower/safety/truth.do",
    "https://www.google.com/search?q=abandoned+mall&rlz=1C5CHFA_enKR928KR928&sxsrf=AOaemvKlMfANUtg2xC4DFC-mglc-G9lE2w:1637657537321&source=lnms&tbm=isch&sa=X&ved=2ahUKEwjj55neja70AhViIaYKHb_FA4MQ_AUoAXoECAEQAw&biw=1405&bih=848&dpr=2",
    "https://www.urbandictionary.com/define.php?term=Muzak",
    "https://us.moodmedia.com/ga/muzak-background-music/",
    "https://www.bensound.com/royalty-free-music/track/the-elevator-bossa-nova",
    "https://www.theguardian.com/cities/2018/aug/14/how-air-conditioning-created-modern-city"
];


function preload (scene: Phaser.Scene) {
	scene.load.image('tiles', [ '/assets/trans_tiles.png', '/assets/trans_tiles.png' ]);
	scene.load.image('car', '/assets/car90.png');
	scene.load.image('background', ['/assets/extraplex_logo.png', '/assets/extraplex_logo_n.png']);
	scene.load.tilemapCSV('map', '/assets/grid.csv');
}

function uniqueRandoms(qty:number, min:number, max:number){
	var rnd;
	var arry: number[]=[];
	do { 
		do { rnd=Math.floor(Math.random()*max)+min }
		while(arry.includes(rnd))
		arry.push(rnd);
	} while(arry.length<qty)
	return arry;
}

function create (scene: Phaser.Scene) {
	var map = scene.make.tilemap({ key: 'map', tileWidth: 32, tileHeight: 32 });
	var tileset = map.addTilesetImage('tiles', undefined, 32, 32, 1, 2);
	layer = map.createLayer(0, tileset, 0, 0);
	numbers = uniqueRandoms(3, 0, 9);
	for (let index = 0; index < 3; index++) {
		selectedLinks.push(links[numbers[index]]);
	}
	const spaceman = scene.add.tileSprite(400, 300, 800, 600, 'background').setPipeline('Light2D');
	spaceman.tileScaleX = 0.16;
	spaceman.tileScaleY = 0.16;
	player = scene.physics.add.sprite(32+16, 32+16, 'car');
	cursors = scene.input.keyboard.createCursorKeys();
	light = scene.lights.addLight(0, 0, 200).setScrollFactor(0.0);
	scene.lights.enable().setAmbientColor(0x555555);
	spotlight = scene.lights.addLight(400, 300, 280).setIntensity(3);
	offsets = [ 0.1, 0.3, 0.5, 0.7 ];
}

function update () {
	if (cursors!.up!.isDown && player!.body.touching.down) {
		player!.setVelocityX(-30);
		player!.setVelocityY(-30);
	}
  if (cursors!.left!.isDown){
		var tile = layer.getTileAtWorldXY(player!.x - 2, player!.y, true);
		if(tile){
			if (tile!.index === 2){
			}
			else if (tile!.index === 3){
				window.open(selectedLinks[0], '_blank');
			}
			else if (tile!.index === 4){
				window.open(selectedLinks[1], '_blank');
			}
			else if (tile!.index === 5){
				window.open(selectedLinks[2], '_blank');
			}
			else if (tile!.index === 6){
				window.open("https://linktr.ee/ExtraPlex", '_blank');
			}
			else if (tile!.index === 7){
				window.open("https://youtu.be/vWZSL2a90no", '_blank');
			}
			else{
				player!.x -= 2;
				player!.angle = 180;
			}
		}
	}
	else if (cursors!.right!.isDown)
	{
		var tile = layer.getTileAtWorldXY(player!.x + 2, player!.y, true);
		if(tile){
			if (tile!.index === 2){
			}
			else if (tile!.index === 3){
				window.open(selectedLinks[0], '_blank');
			}
			else if (tile!.index === 4){
				window.open(selectedLinks[1], '_blank');
			}
			else if (tile!.index === 5){
				window.open(selectedLinks[2], '_blank');
			}
			else if (tile!.index === 6){
				window.open("https://linktr.ee/ExtraPlex", '_blank');
			}
			else if (tile!.index === 7){
				window.open("https://youtu.be/vWZSL2a90no", '_blank');
			}
			else{
				player!.x += 2;
				player!.angle = 0;
			}
		}
	}
	else if (cursors!.up!.isDown){
		var tile = layer.getTileAtWorldXY(player!.x, player!.y - 2, true);
		if(tile){
			if (tile!.index === 2){
			}
			else if (tile!.index === 3){
				window.open(selectedLinks[0], '_blank');
			}
			else if (tile!.index === 4){
				window.open(selectedLinks[1], '_blank');
			}
			else if (tile!.index === 5){
				window.open(selectedLinks[2], '_blank');
			}
			else if (tile!.index === 6){
				window.open("https://linktr.ee/ExtraPlex", '_blank');
			}
			else if (tile!.index === 7){
				window.open("https://youtu.be/vWZSL2a90no", '_blank');
			}
			else{
				player!.y -= 2;
				player!.angle = -90;
			}
		}
	}
	else if (cursors!.down!.isDown){
		var tile = layer.getTileAtWorldXY(player!.x, player!.y + 2, true);
		if(tile){
			if (tile!.index === 2){
			}
			else if (tile!.index === 3){
				window.open(selectedLinks[0], '_blank');
			}
			else if (tile!.index === 4){
				window.open(selectedLinks[1], '_blank');
			}
			else if (tile!.index === 5){
				window.open(selectedLinks[2], '_blank');
			}
			else if (tile!.index === 6){
				window.open("https://linktr.ee/ExtraPlex", '_blank');
			}
			else if (tile!.index === 7){
				window.open("https://youtu.be/vWZSL2a90no", '_blank');
			}
			else{
				player!.y += 2;
				player!.angle = 90;
			}
		}
  }

	light.x = player!.x;
	light.y = player!.y;
	spotlight.x = player!.x;
	spotlight.y = player!.y;
}

const config = {
	type: Phaser.WEBGL,
	width: 800,
	height: 600,
	pixelArt: true,
	backgroundColor: 'none',
	physics: {
		default: 'arcade'
	},
	scene: {
		preload: function() { preload(this as unknown as Phaser.Scene) },
		create: function() { create(this as unknown as Phaser.Scene) },
		update: update
	}
};

function Hwi() {
  return (
		<div className="hwi" style={{height:window.innerHeight+'px'}}>
			<BackButton />
			<div className="hwi__game" style={{display: (window.innerWidth>768)? "inherit" : "none"}}>
				<ExampleGame />
			</div>
			<div className="hwi__arrow" style={{display: (window.innerWidth>768)? "inherit" : "none"}}>
				<Arrow />
			</div>
			<div className="hwi__mobile-view" style={{display: (window.innerWidth<=768)? "inherit" : "none"}}>
				<a href="https://youtu.be/vWZSL2a90no" target="_blank">
					<HWILogo className="hwi__mobile-view-logo"/>
				</a>
			</div>
			<Loading />
			<Intro
				name= "HWI"
				title= "- ExtraPlex"
				artistIntro= "음악가, 영상 제작자.<br />목소리와 컴퓨터를 주재료로 사용한다.<br />EP &lt;ExtraPlex&gt;(2019), '업체eobchae'의 사운드트랙 앨범 &lt;The Decider’s Chamber&gt;(2021)를 발표했다."
				projectDescription= "상권의 쇠락으로 사람의 발길이 끊긴 지<br />𝓷년이 지난 한 대형 쇼핑몰. 이 쇼핑몰에는 귀신이 출몰한다든가, 누군가 매대에 남겨진 상품들로 숙식을 하고 있다든가 하는 괴소문이 돈다."
			>
			</Intro>
    </div>
  );
}

const ExampleGame = observer(() =>
  <Game config={config}>
		<div
			style={{
				position: "relative",
				fontSize: 32,
				color: "#ededed",
				top: config.height - 48,
				left: 32
			}}
		>
    </div>
  </Game>
)

export default Hwi;