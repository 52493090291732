import Figure1 from '../assets/p1.png';
import Figure2 from '../assets/p2.png';
import Figure3 from '../assets/p3.png';
import Figure4 from '../assets/p4.png';
import Figure5 from '../assets/p5.png';
import Figure6 from '../assets/p6.png';
import Figure7 from '../assets/p7.png';
import Figure8 from '../assets/p8.png';
import Figure9 from '../assets/p9.png';
import '../styles/patrick.scss';
import BackButton from '../snippets/BackButton';
import Loading from '../snippets/Loading';
import Intro from '../snippets/Intro';

function Patrick() {
  return (
    <div className="patrick" style={{height:window.innerHeight+'px'}}>
      <BackButton />
      <Loading />
      <Intro
				name= "Patrick Lowry"
				title= "- view from the west"
				artistIntro= {`
          Patrick Lowry lives in Cornwall UK. He moved to there when offered a lecturing post at Falmouth College of Art, where he also later completed an MA in fine art. He subsequently established and ran a Fine Art Degree course at Cornwall College. He now spends the majority of his time in the studio making large-scale often site spacific installation pieces, which explore our relationships to places and environments within social and political contexts. In 2009, along with two other artists, he established the artist-led project space - Back Lane West - which, through a residency programme, offers national and international artists the opportunity of developing new work in the context of a work and exhibition space in Cornwall.
        `}
				projectDescription= {`
          “광고에는 이런 아파트 건물들을 묘사한 기괴한 삽화가 들어가 있었다. 이런 이미지들은 일종의 유토피아적 꿈을 묘사하려 했던 것 같지만 손쉽게 디스토피아의 이미지가 될 수도 있을 것 같다고 느끼기도 했다.”
        `}
			>
			</Intro>
      <div className="patrick__about-container">
        <div className="patrick__about-txt-container">
          <div className='patrick__about-title'>
            <h1 className='patrick__about-title-txt'>
              <b>view from the west</b>
            </h1>
            <h2>Patrick Lowry</h2>
            <br />
          </div>
          <p className="patrick__p">
            * Scroll down for English
          </p>
          <p className="patrick__p">
            서울을 처음 방문한 것은 2016년에 열린 협업 프로젝트 “드라이브-인”에 참여하면서였다. 내가 서울에 기대한 게 대체 무엇이었는지 확실치 않았지만, 어쨌든 서울은 내가 기대한 것과 달랐다. 실제 모습보다 다소 정돈되지 않은 도시, 그리고 도시와 교외가 좀 더 뒤섞인 것을 상상했던 걸까? “드라이브-인” 프로젝트를 중심으로 이뤄졌던 첫 번째 서울 방문 당시에는 서울을 알아볼 수 있는 시간이 부족했다. 하지만 다시 돌아오고 싶다는 생각이 들 정도로는 둘러보았고, 그래서 서울을 더 잘 알아볼 수 있도록 2018년에 다시 한 번 방문했다.
          </p>
          <p className="patrick__p">
            서울에 대한 대체적인 인상은 모든 게다 새것이라는 점이다. 궁궐과 사찰이 있고, 오래된 집들이 모여 있는 지역이 조금 있다는 건 알고 있지만, 런던, 파리, 리옹, 베를린 등 내게 익숙한 유럽의 도시들과 달리 서울의 건축은 오래된 역사의 층위를 담고 있지 않은 것 같다. 서울은 끊임없이 자신을 재생하고, 계속해서 과거를 대체하는 것처럼 보인다. 서울에 두 번째로 방문했을 때는 이사를 갈 아파트를 찾고 있던 한국인 친구와 함께 시간을 보내기도 했다. 어떤 아파트를 찾고 있는지 묻자, 이런 대답을 들었다. “오래된 집, 그러니까 1980년대의 집”. 1980년대는 내가 아는 그 어떤 도시에서도 오래된 것이라고 여겨지지 않는다. 1780년대는 되어야, 혹은 1880년대 쯤 되어야 오래된 것이라고 여긴다. 
          </p>
          <p className="patrick__p">
            건물이 모두 새것일 뿐 아니라 지하철 노선도 새것이고, 널찍하며 깨끗해서 놀랐다. 이런 모습에는 이유가 있다는 걸 알게 되었다. 서울은 남한의 다른 지역과 마찬가지로 식민지, 전쟁, 급격한 경제 발전이 뒤섞인 격동의 현대사를 겪었기 때문이다.
          </p>
          <p className="patrick__p">
            서울에는 젊은이들도 많은 것 같은데, 서울에서 마주친 몇 안 되는 노인들은 마치 사라져버린 과거에서 온 것 같았다. 느릿느릿하고 어색한 그들의 걸음에 알맞는 더 느린 시간이랄까. 아마 경제적인 이유에서 그런 것 같지만, 서울에서 종종 나이 든 사람들을 봤을 때 그들은 저임금 노동에 종사 중이었다. 동갑내기들과 함께 길가에 앉아서 마늘을 까서 팔고, 야채를 팔거나, 자전거 바퀴가 달린 수레에 재활용에 쓸 골판지를 수집하는 일 말이다.
          </p>
          <p className="patrick__p">
            이상한 모순을 발견하기도 했다. 유럽의 여러 현대적 도시보다 서울에서 편안함을 느끼는 속도가 더 빨랐는데, 말하고 쓰는데서 영어 사용이 유럽에서보다 더 빈번했기 때문이다. 모든 대중교통수단은 한국어, 일본어, 중국어와 더불어 영어로 정보를 제공했고, 식당의 메뉴를 비롯한 온갖 표지판도 영어로 쓰여 있곤 했다. 대중교통의 영어 안내방송은 정말 재미있었는데, 항상 여성의 목소리로 다음 역이나 정류장을 알려주었다. 마치 잠자리에 초대하기라도 하는 것처럼 살짝 매혹적인 목소리로 말이다! 주변에 영어가 너무 많아서 고향에 온 것처럼 편안하게 느끼기도 했지만, 동시에 내가 서울에 있는 아주 적은 수의 외국인 혹은 최소한 동아시아인이 아닌 사람에 속하는 것처럼 느끼기도 했다. 서울에는 내 모국의 수도인 런던에서 익숙한 인종적 다양성이 존재하지 않았고, 피부색이 아주 어두운 사람들을 거의 보지 못했다는 사실을 깨달았다.
          </p>
          <p className="patrick__p">
            모든 것이 새것처럼 보였다고는 하지만, 도시의 한 구역인 을지로는 어딘지 모르게 상당히 달랐다. 을지로에는 서울의 과거에 대한 아주 독특한 모습이 있었다. 1950년대 이후로 크게 변한 적 없는 온갖 활동의 중심지에는 번쩍거리는 새 건물도 없었고, 그저 수백 개의 작은 가게와 작업장이 허름한 건물에 자리하고 있었다. 안타깝게도 서울에서 아주 특별한 이 구역이 재개발을 향한 가차없는 공세를 더는 버틸 수 없을 것 같다는 느낌을 받았다. 을지로가 이미 재개발 및 재건축으로둘러싸여 있다는 점을 알 수 있었고, 독특하고 또한 다른 것과 바꿀 수 없는 이 장소가 천천히 잠식당하고 있다는 것도 보였다. 을지로는 머지않아 정체를 알 수 없는 또 다른 아파트 건물과 특별할 것 없는 상점 및 카페,, 식당이 들어선 지역이 될 거라고 생각했다. 
          </p>
          <p className="patrick__p">
            서울을 처음 방문했을 때부터 도시 경관의 많은 부분이 서로 상당히 비슷한 모습의 고층 아파트 단지로 이뤄졌다는 것을 인지할 수 있었다. 서로 너무 비슷해서 구별을 위해 콘크리트 외벽에 거대한 번호를 붙여야 할 정도로. 새 아파트를 찾고 있던 친구와 함께 부동산 중개업체를 여러 곳 찾아갔는데, 고층 아파트 임대나 구매를 위한 광고를 보게 되었다. 광고에는 이런 아파트 건물들을 묘사한 기괴한 삽화가 들어가 있었다. 이런 이미지들은 일종의 유토피아적 꿈을 묘사하려 했던 것 같지만 손쉽게 디스토피아의 이미지가 될 수도 있을 것 같다고 느끼기도 했다. 
          </p>
          <p className="patrick__p">
            젊은이들로 도시가 북적인다는 느낌과 더불어, 아주 많은 젊은 여성과 남성들이 성형수술을 받았다는 점, 피부 미백과 코 및 눈꺼풀 성형이 도처에 있다는 점을 점차 깨닫게 되었다. 서울이라는 도시만 끊임없이 스스로를 개조하는 것이 아니라 서울의 젊은이들 역시 자신에게 똑같은 짓을 저지르고 있는 것 같았다.
          </p>
          <p className="patrick__p">
            마지막으로 기억에 남는 한 가지는 밤 10시에 버스에 탔는데 학생들이 한가득 몰려들었던 일이다. 그렇게 늦은 밤 시간에 막 학교에서 나왔다는 사실이 정말로 놀라웠다!
          </p>
          <p className="patrick__p">
            이런 내용이 서울의 진정한 모습이 아닐 수도 있다는 점을 기꺼이 인정한다. 나는 서울의 특정한 일부만 알고 있으며내가 보지 못한 부분도 많다. 하지만 다시 서울을 방문해 더 많은 것들을 알게 되기 전까지는 여기까지가 내게 남겨진 모습이라 하겠다.
          </p>
          <p className="patrick__p">
            I first visited Seoul in 2016 as part of a collaborative art project; Drive-in. Seoul was not what I had expected, although I’m not too sure what I really expected. I think I was envisaging a less organised city and more of a mixture of urban and rural maybe?
          </p>
          <p className="patrick__p"> 
            I felt my first visit, which was focused around the Drive-in project, did not give me time to get to know Seoul, but I had seen enough to know I wanted to come back, and so returned again in 2018 to see if I could get to know the city better.
          </p>
          <p className="patrick__p">
            My main impression of Seoul is that everything is new. I know there are the palaces and temples and some small areas of older houses, but, unlike other cities I am familiar with in Europe - London, Paris, Lyon, Berlin - Seoul doesn’t seem to have the older layers of history captured in its architecture. The city seems to be continually regenerating itself, constantly replacing its past. On my second visit to Seoul I spent some time with a Korean friend who was looking for a new apartment. When I asked what sort of apartment are you looking for, she said, “something old, something from the 1980s”. The 1980s in any other city I know would not be considered old - 1780 or maybe 1880 would be old. 
          </p>
          <p className="patrick__p"> 
            Not only are the buildings all new, but also I was struck by how new, spacious and clean the metro network was. I realise there are reasons for this; Seoul, like the rest of South Korea, has had a turbulent recent history, acombination of colonisation, war, and rapid economic development.
          </p> 
          <p className="patrick__p">
            The city also seems to be populated by the young, with the few older inhabitants I saw seemingly coming from a lost past; a slower time, which would match their slow, shuffling way of walking. I guess it’s for economic reasons, but often when I saw older Seoul inhabitants they were engaged in some low paid task, sitting on the pavement with a group of their contemporaries, peeling and selling garlic cloves, selling other vegetables, or collecting cardboard for recycling in spoke-wheeled push-carts.  
          </p>
          <p className="patrick__p">
            I found odd contradictions. I quickly felt at home in Seoul, more so than in many modern cities in Europe, as there was so much spoken and written English. All public transport gave information in English alongside Korean, Japanese, and Chinese, and restaurant menus, and signs of all sorts, were often in English. I found the English announcements on public transport really amusing to listen to, the voice was always female, and announced the next station or bus stop in a slightly seductive tone as if she was inviting you to bed! But whilst I felt at home with so much English around me I also felt like one of the very few non-Koreans or at least non-East Asians. There was not the ethnic diversity I am familiar with in my home capital city of London, and in Seoul I noticed that I saw few, if any, people with really dark skin.
          </p>
          <p className="patrick__p">
            I say everything seemed new, but one area of the city, Euljiro, was something very different. Here was a very particular look back at Seoul’s past, no new shiny architecture here, just hundreds of small shops and specialist workshops, in ramshackle buildings all crowded together in a real hive of activity that has been largely unchanged since the 1950’s. Unfortunately I got the feeling this very special part of Seoul could not survive much longer the relentless drive to redevelop, already I could see the area was surrounded by building activity, slowly creeping in on this unique and irreplaceable place. I guess soon Euljiro will become another area of anonymous apartment buildings and characterless shops, cafes and restaurants. 
          </p>
          <p className="patrick__p">
            I was, from my first visit to Seoul, very aware that a lot of the cityscape was made up of tall apartment blocks all looking very similar, to the extent that they had to have large identifying numbers high up on their concrete exteriors to distinguish one from another. With my friend who was looking for a new apartment, we visited several estate agents where I came across the publicity for renting or buying apartments in these tower block buildings. This advertising literature had odd illustrations of these apartment buildings, which I guess were trying to portray some sort of utopian dream, but somehow it felt these could easily be images of dystopia. 
          </p>
          <p className="patrick__p">
            Along with feeling the city was populated by the young, I became increasingly aware that a lot of young women, and men, have plastic surgery, and that there were advertisements everywhere for skin whitening, and nose and eyelid surgery, it seemed it was not just the city that was constantly remodelling itself, the young population were doing the same thing to themselves.
          </p>
          <p className="patrick__p">
            One last thing I remember was being on a bus at 10pm and loads of school kids got on, I was really surprised to find that they were just finishing school at that time of night! 
          </p>
          <p className="patrick__p">
            I am happy to acknowledge that this might not be a true picture of Seoul, I only got to know  particular parts of the city and that there are many areas I haven’t seen, but until I can revisit and discover more, these are the images I’m left with.
          </p>
          
          <div className='patrick__img'>
            <img src={Figure1} />
          </div>
          <div className='patrick__img'>
            <img src={Figure2} />
          </div>
          <div className='patrick__img'>
            <img src={Figure3} />
          </div>
          <div className='patrick__img'>
            <img src={Figure4} />
          </div>
          <div className='patrick__img'>
            <img src={Figure5} />
          </div>
          <div className='patrick__img'>
            <img src={Figure6} />
          </div>
          <div className='patrick__img'>
            <img src={Figure7} />
          </div>
          <div className='patrick__img'>
            <img src={Figure8} />
          </div>
          <div className='patrick__img'>
            <img src={Figure9} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Patrick;