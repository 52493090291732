import '../styles/sima.scss';
import BackButton from '../snippets/BackButton';
import Loading from '../snippets/Loading';

function Sima() {
  return (
    <div className="sima" style={{height:window.innerHeight+'px'}}>
      <BackButton />
      <Loading />
      <iframe className="sima__iframe" src="/Seoul2121/index.html">
      </iframe>
    </div>
  );
}

export default Sima;