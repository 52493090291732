import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Hwi from './components/Hwi.tsx';
import Jiyoung from './components/Jiyoung.tsx';
import Hyojae from './components/Hyojae';
import Dongkyun from './components/Dongkyun';
import Sima from './components/Sima';
import SimaMovie from './components/SimaMovie';
import Yun from './components/Yun';
import Keys from './components/Keys';
import Coop from './components/Coop';
import Abhijan from './components/Abhijan';
import Tom from './components/Tom';
import Patrick from './components/Patrick';
import Home from './components/Home';
import UseGaTracker from './UseGaTracker'
import './App.scss';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact={true} component={Home} />
          <Route exact={true} path="/hwi" component={Hwi} />
          <Route exact={true} path="/jiyoung" component={Jiyoung} />
          <Route exact={true} path="/jiyoung/keys" component={Keys} />
          <Route exact={true} path="/hyojae" component={Hyojae} />
          <Route exact={true} path="/dongkyun" component={Dongkyun} />
          <Route exact={true} path="/seoul2121" component={Sima} />
          <Route exact={true} path="/seoul2121/trailer" component={SimaMovie} />
          <Route exact={true} path="/yun" component={Yun} />
          <Route exact={true} path="/coop" component={Coop} />
          <Route exact={true} path="/abhijan-toto-bela" component={Abhijan} />
          <Route exact={true} path="/patrick-lowry" component={Patrick} />
          <Route exact={true} path="/tom-huckleberry" component={Tom} />
        </Switch>
        <UseGaTracker />
      </Router>
    </div>
  );
}

export default App;
