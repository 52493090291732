import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Cookie from '../assets/yuryu-02.svg';
import Cookie2 from '../assets/yuryu-03.svg';
import Cookie3 from '../assets/yuryu-04.svg';
import Cookie4 from '../assets/yuryu-05.svg';
import Cookie5 from '../assets/yuryu-06.svg';
import Cookie6 from '../assets/yuryu-07.svg';
import Cookie7 from '../assets/yuryu-08.svg';
import Cookie8 from '../assets/yuryu-09.svg';
import Cookie9 from '../assets/yuryu-10.svg';
import Cookie10 from '../assets/yuryu-11.svg';
import Cookie11 from '../assets/yuryu-12.svg';
import Cookie12 from '../assets/yuryu-13.svg';
import { ReactComponent as SfacLogo } from '../assets/sfac_logo.svg';
import { ReactComponent as SeoulLogo } from '../assets/seoul_logo.svg';
import { ReactComponent as SeoulScrollArrowRight } from '../assets/seoul-scroll-arrow-right.svg';
import { ReactComponent as SeoulScrollArrowLeft } from '../assets/seoul-scroll-arrow-left.svg';
import { ReactComponent as Title } from '../assets/title1-01.svg';
import { ReactComponent as Seoul2121 } from '../assets/seoul2121-05.svg';
import Button from '../snippets/Button';

const CarouselCell = ({cellCount, theta, radius, selectedIndex, artistTitle}) => {
  const [prevCellCount, setPrevCellCount] = useState(20);
  let carouselRef = useRef(null);
  let cells = [];
  let cookies = [Cookie, Cookie2, Cookie3, Cookie4, Cookie5, Cookie6, Cookie7, Cookie8, Cookie9, Cookie10, Cookie11, Cookie12];
  for(var i = 0; i<cellCount; i++){
    var mystyle;
    if(i<prevCellCount){
      var cellAngle = theta * i + 180*Math.random();
      mystyle = {
        opacity: 1,
        transform: 'rotateX(' + cellAngle + 'deg) rotateZ('+360*Math.random()+'deg) translateZ(' + radius + 'px)'
      };
    }else{
      mystyle = {
        opacity: 0,
        transform: 'none'
      };
    }
    cells.push(<div className="carousel__cell transfrom-gpu" style={mystyle}><img src={cookies[Math.floor(12*Math.random())]} /></div>);
  }

  const rotateCarousel = () => {
    var angle = theta * selectedIndex * -1;
    carouselRef.current.style.transform = 'translateZ(' + -radius + 'px) ' + 
      'rotateX(' + angle + 'deg)';
  }
  const showName = (n) => {
    if(document.getElementById("artistTitle")){
      document.getElementById("artistTitle").style.display="inherit";
      document.getElementById("artistTitle").innerText = n;
    }
  }
  const hideName = (n) => {
    if(document.getElementById("artistTitle")){
      document.getElementById("artistTitle").style.display="none";
      document.getElementById("artistTitle").innerText = "";
    }
  }

  useEffect(() => {
    rotateCarousel();
    setPrevCellCount(cellCount);
  }, [selectedIndex]);
  
  return (
    <div className="scene w-full" onMouseOver={(e) => {showName(artistTitle)}} onMouseOut={(e) => {hideName(artistTitle)}}>
      <div className="carousel" id={artistTitle} ref={carouselRef}>
        {cells}
      </div>
    </div>
  );
};

function App() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedIndexForMobile, setSelectedIndexForMobile] = useState(0);
  const [theta, setTheta] = useState(24);
  const [radius, setRadius] = useState(0);
  const [cellCount, setCellCount] = useState(15);
  const artistArr = ['김효재\n여는 글', 'Patrick Lowry', '톰앤허클베리', '위지영', '박동균', '최윤',  'HWI',  '코옵',  'Abhijan Toto+Bela'];
  var cellSize = 1000;
  var aboutIsOpened = false;
  let about = useRef(null);
  let home = useRef(null);
  let rightArrow = useRef(null);
  let leftArrow = useRef(null);

  useEffect(() => {
    var tempRadius = Math.round( ( cellSize / 2) / Math.tan( Math.PI / cellCount) );
    setRadius(tempRadius);
    var tempTheta = 360 / cellCount;
    setTheta(tempTheta);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSelectedIndex(selectedIndex => selectedIndex+1);
    }, 10000);
    return () => clearInterval(intervalId);
  }, [selectedIndex]);

  useEffect(() => {
    const intervalId2 = setInterval(() => {
      setSelectedIndexForMobile(selectedIndexForMobile => selectedIndexForMobile+1);
      if(window.innerWidth<768){
        mobileBlur();
      }
    }, 5000);
    return () => clearInterval(intervalId2);
  }, [selectedIndexForMobile]);

  function mobileBlur() {
    var sceneDivs = document.getElementsByClassName('scene');
    for (let index = 0; index < sceneDivs.length; index++) {
      const element = sceneDivs[index];
      element.style.filter = 'blur(0px)';
      element.style.backgroundColor = 'transparent';
      element.style.pointerEvents = 'none';
      element.parentNode.style.cursor = 'default';
      if(index == selectedIndexForMobile%11){
        if(selectedIndexForMobile%11 < 9){
          element.style.filter = 'blur(10px)';
          element.style.backgroundColor = 'rgba(200,180,255,.36)';
          element.style.pointerEvents = 'all';
          element.parentNode.style.cursor = 'pointer';
          showName(artistArr[index]);
        }
      }
    }
    if(selectedIndexForMobile%11 >= 9){
      hideName();
    }
  }

  const showName = (n) => {
    if(document.getElementById("artistTitle")){
      document.getElementById("artistTitle").style.display="inherit";
      document.getElementById("artistTitle").innerText = n;
    }
  }
  const hideName = () => {
    if(document.getElementById("artistTitle")){
      document.getElementById("artistTitle").style.display="none";
      document.getElementById("artistTitle").innerText = "";
    }
  }

  function openAbout(e) {
    if (aboutIsOpened){
      about.current.style.display = "none";
      aboutIsOpened = false;
    }else{
      about.current.style.display = "inherit";
      aboutIsOpened = true;
    }
  }

  function scrollHorizontally(d){
    if(d==='right'){
      home.current.scrollTo(window.innerWidth*0.6,0);
      rightArrow.current.style.opacity = 0;
      leftArrow.current.style.opacity = 1;
      rightArrow.current.style.pointerEvents = 'none';
      leftArrow.current.style.pointerEvents = 'all';
    }else if(d==='left'){
      home.current.scrollTo(0,0);
      rightArrow.current.style.opacity = 1;
      leftArrow.current.style.opacity = 0;
      rightArrow.current.style.pointerEvents = 'all';
      leftArrow.current.style.pointerEvents = 'none';
    }
  }

  return (
    <div>
      <div className="artistTitle" id="artistTitle"></div>
      <div className="carousel-options">
        <p className="button-section">
          <Button openAbout={openAbout}></Button>
          <Link to={(window.innerWidth>768? '/seoul2121' : '/seoul2121/trailer')}>
            <div className="seoul2121"><Seoul2121 /></div>
          </Link>
        </p>
      </div>
      <div className='titleContainer'>
        <div className="title">
          <Title />
        </div>
      </div>
      <button ref={rightArrow} onClick={() => scrollHorizontally('right')} className='scrollArrowContainer scrollArrowContainer--right z-sub-nav'>
        <SeoulScrollArrowRight />
      </button>
      <button ref={leftArrow} onClick={() => scrollHorizontally('left')} className='scrollArrowContainer scrollArrowContainer--left z-sub-nav'>
        <SeoulScrollArrowLeft />
      </button>
      <div className="about-outer-container" ref={about} onClick={openAbout}>
          <div className="about-container">
            <div className='about-txt-container'>
              <div className='about-txt-container-inner mb-s text-p1'>
                <p>
                  서울유령미감 2021은 서울의 사용자 경험을 모은 것이다. 마스크를 착용한 얼굴들, PCR 검사를 위해 길게 선 줄, 줌에서의 어색한 인사, 지나친 즐거움은 제한된 헬스장, 그럼에도 불구하고 여전한 성형외과 광고들, 24시간 배달과 세탁 외주 애플리케이션, 천장이 무너진 대형 쇼핑몰, 급등과 급락 둘 중 하나만 아는 부동산에 이르기까지, 지난 삼 년간 서울의 변화는 지금까지와는 다른 서울로의 모험을 예고하고 있다. 서울유령미감 2021은 이 모험을 위한 지도로 작동한다.
                </p>
              </div>
              <div className='about-txt-container-inner mt-l mb-s text-p2'>
                <div className='divider'></div>
                <p>
                  서울유령미감 2021
                </p>
                <p>
                  서울미감 X 유령회사
                </p>
                <p>
                  HWI, 김효재, 박동균, 위지영, 최윤, 코옵, 톰앤허클베리, 패트릭 로우리, 아비잔 토토+벨라
                </p>
                <br />
                <p>
                  1차 공개 2021년 12월 28일 화요일 밤 9시
                </p>
                <p>
                  2차 공개 2022년 2월 28일 월요일 밤 9시
                </p>
                <br />
                <p>
                  주최·주관 김효재 (서울미감)
                </p>
                <p>
                  기획 김얼터 (유령회사)
                </p>
                <p>
                  웹 개발/디자인 김나희
                </p>
                <p>
                  그래픽 디자인 김소희
                </p>
                <p>
                  SEOUL 2121 3D 애플리케이션 김시마
                </p>
                <p>
                  후원 서울특별시, 서울문화재단
                </p>
                <p>
                  2021년 코로나19 예술지원 &lt;ART MUST GO ON&gt; 선정작
                </p>
              </div>
              <div className='about-txt-container-inner mb-s'>
                <br />
                <div className='divider'></div>
                <p>
                  SEOUL-EURYUNG-AESTETIC 2021
                </p>
                <p>
                  SEOUL AESTETIC X EURYUNGCOMPANY
                </p>
                <p>
                  HWI, Hyojae Kim, Dongkyun Vak, Jiyoung Wi, Yun Choi, Co-op, Tom & Huckleberry, Patrick Lowry, Abhijan Toto+Bela
                </p>
                <br />
                <p>
                  First Release 28 December 2021 Tue 9 pm
                </p>
                <p>
                  Second Release 28 February 2022 Mon 9 pm
                </p>
                <br/>
                <p>
                  Organized by Hyojae Kim (SEOUL AESTHETIC)
                </p>
                <p>
                  Directing Alter Kim(EURYUNGCOMPANY)
                </p>
                <p>
                  Web Development/Design Nahee Kim
                </p>
                <p>
                  Graphic Design Sohee Kim
                </p>
                <p>
                  SEOUL 2121 3D Application Sima Kim
                </p>
                <p>
                  Funded by Seoul, SFAC
                </p>
                <br />
                <div class="w-24">
                  <SfacLogo />
                </div>
                <div class="w-20 mt-5">
                  <SeoulLogo />
                </div>
              </div>
            </div>
          </div>
        </div>
      <div className="home overflow-x-scroll" ref={home} style={{height:window.innerHeight+'px'}}>
        <div className='backdrop'></div>
        <div className="carousel-container h-full flex flex-row">
          <div className="flex-grow">
            <Link to="/hyojae">
              <CarouselCell cellCount={cellCount} theta={theta} radius={radius} selectedIndex={selectedIndex} artistTitle={"김효재\n여는 글"}/>
            </Link>
          </div>
          <div className="flex-grow">
            <Link to="/patrick-lowry">
              <CarouselCell cellCount={cellCount} theta={theta} radius={radius} selectedIndex={selectedIndex} artistTitle={"Patrick Lowry"} />
            </Link>
          </div>
          <div className="flex-grow">
            <Link to="/tom-huckleberry">
              <CarouselCell cellCount={cellCount} theta={theta} radius={radius} selectedIndex={selectedIndex} artistTitle={"톰앤허클베리"} />
            </Link>
          </div>
          <div className="flex-grow">
            <Link to="/jiyoung">
              <CarouselCell cellCount={cellCount} theta={theta} radius={radius} selectedIndex={selectedIndex} artistTitle={"위지영"}/>
            </Link>
          </div>
          <div className="flex-grow">
            <Link to="/dongkyun">
              <CarouselCell cellCount={cellCount} theta={theta} radius={radius} selectedIndex={selectedIndex} artistTitle={"박동균"} />
            </Link>
          </div>
          <div className="flex-grow">
            <Link to="/yun">
              <CarouselCell cellCount={cellCount} theta={theta} radius={radius} selectedIndex={selectedIndex} artistTitle={"최윤"} />
            </Link>
          </div>
          <div className="flex-grow">
            <Link to="/hwi">
              <CarouselCell cellCount={cellCount} theta={theta} radius={radius} selectedIndex={selectedIndex} artistTitle={"HWI"} />
            </Link>
          </div>
          <div className="flex-grow">
            <Link to="/coop">
              <CarouselCell cellCount={cellCount} theta={theta} radius={radius} selectedIndex={selectedIndex} artistTitle={"코옵"} />
            </Link>
          </div>
          <div className="flex-grow">
            <Link to="/abhijan-toto-bela">
              <CarouselCell cellCount={cellCount} theta={theta} radius={radius} selectedIndex={selectedIndex} artistTitle={"Abhijan Toto+Bela"} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
