import { useEffect, useRef } from 'react';
import '../styles/yun.scss';
import BackButton from '../snippets/BackButton';
import Loading from '../snippets/Loading';
import Intro from '../snippets/Intro';

function Yun() {
  const yunVideo = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const timerYun = setTimeout(() => {
      if (null !== yunVideo.current) {
        yunVideo.current.play();
      }
    }, 6000);
    return () => clearTimeout(timerYun);
  }, []);

  return (
    <div className="yun" style={{height:window.innerHeight+'px'}}>
      <BackButton />
      <Loading />
      <video controls ref={yunVideo} className="yun__video">
        <source
          src="/sample.mp4"
          type="video/mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
      <Intro
        name= "최윤 Yun Choi"
        title= "- 샘플"
        artistIntro= {`
          최윤은 다중의 시간과 상투성이 조성하는 사회적 풍토를 포착하고 엮어서 영상, 설치, 퍼포먼스 등으로 보여준다. 특히 동시대 한국 사회의 일상적이면서 기이한 잔류의 이미지, 구절, 행동 양식, 그리고 이들이 내포하는 집단의 신념과 감정에 집중해왔다. 주변 환경과 관계를 유동적으로 바라보는 시각으로 서사에 도약을 감행하며, 이를 수행하기 위한 주술적이고 직감적인 목소리와 움직임, 통속적이고 변종적인 미디어와 오브제를 사용한다. 
          두산갤러리 서울과 두산갤러리 뉴욕(2020), 아트선재센터 프로젝트 스페이스(2017)에서 개인전을 열었으며, 국립현대미술관(2021), 국립아시아문화전당(2020), 플랫폼엘 컨템포러리 아트센터(2019), 아트선재센터(2019), 아르코 미술관(2019), 부산비엔날레(2018), 광주비엔날레 파빌리온 전시(2018), 북서울미술관(2017), 국제갤러리(2017), 서울시립미술관(2016), 하이트컬렉션(2014) 등의 단체전에 참여하였다. 
        `}
        projectDescription= {`
          &lt;샘플&gt;(2021)은 선거철마다 등장하는 선거 캠페인 노래가 얼마나 헛되며 공허한지 주목한다. 이 노래의 샘플들은 대부분 기호 0번 행복당 홍길동을 선전한다. 홍길동은 조선의 이름난 도둑이자 대체물이다. &lt;샘플&gt;은 언리얼 엔진의 무대 샘플과 선거송 샘플을 결합하여 제작되었다.
        `}
      >
      </Intro>
    </div>
  );
}

export default Yun;