import { useEffect } from 'react';
import Game from "reactified-phaser/Game";
import {observer} from "mobx-react"
import '../styles/jiyoung.scss';
import BackButton from '../snippets/BackButton';
import Loading from '../snippets/Loading';
import Intro from '../snippets/Intro';

var container: Phaser.GameObjects.Container;

function preload (scene: Phaser.Scene) {
  scene.load.image('doorlock', ['/assets/doorlock22.png', '/assets/doorlock22.png']);
  scene.load.image('card', '/assets/card22.png');
}

function create (scene: Phaser.Scene) {
	const tilesprite = scene.add.sprite(480, 270, 'doorlock');
	container = new Phaser.GameObjects.Container(scene, 300,)
	const card = scene.add.sprite(-500,-500, 'card');
	card.scaleX=0.4;
	card.scaleY=0.4;
	scene.lights.enable();
	scene.lights.setAmbientColor(0x808080);
	var spotlight = scene.lights.addLight(0, 0, 80).setIntensity(1);

	scene.input.on('pointermove', function (pointer: Phaser.Geom.Point) {
		spotlight.x = pointer.x;
		spotlight.y = pointer.y;
		card.x = pointer.x;
		card.y = pointer.y;
	});

	scene.input.on('pointerdown', function (pointer: Phaser.Geom.Point) {
		if((pointer.x>450)&&(pointer.x<600)){
			if((pointer.y>200)&&(pointer.y<380)){
				if(window.innerWidth<768){
					window.location.href = '/jiyoung/keys';
				}else{
					window.open("/assets/keys.zip", '_blank');
				}
			}	
		}
	});
}

function update () {
}

const config = {
	type: Phaser.WEBGL,
	parent: 'phaser-example',
	width: 960,
	height: 540,
	physics: {
		default: 'arcade'
	},
	antialias:true,
	scene: {
		preload: function() { preload(this as unknown as Phaser.Scene) },
		create: function() { create(this as unknown as Phaser.Scene) },
		update: update
	}
};

function Jiyoung() {
  return (
    <div className="jiyoung" style={{height:window.innerHeight+'px'}}>
      <BackButton></BackButton>
      <Loading />
      <div className="jiyoung__game" style={{cursor:"none"}}>
				<ExampleGame/>
			</div>
			<Intro
        name= "위지영 Jiyoung Wi"
        title= "- Keys to heaven and hell<br />(forgot to label them)"
        artistIntro= {`
					서울을 중심으로 활동하는 소설가이자 음악가. 제도와 비제도 사이에서 글을 쓰고 사운드를 다룬다. 주로 파운드 사운드, 필드 레코딩, 스포큰 워드에 초점을 맞춰 사운드 스케이프의 허구적 내러티브를 구축하고 부수는 작업을 한다. 아시아 실험-전자 음악 컴필레이션 'Intimate Ghosting'을 기획했다. 비애클럽 공동 운영진. 
        `}
        projectDescription= {`
					“I don’t believe in heaven or hell, it’s not that I don’t want to believe in it…”
        `}
      >
      </Intro>
    </div>
  );
}

const ExampleGame = observer(() =>
  <Game config={config}>
    <div
			style={{
        position: "relative",
        fontSize: 32,
        color: "#ededed",
        top: config.height - 48,
        left: 32
      }}
		>
    </div>
  </Game>
)

export default Jiyoung;