import '../styles/coop.scss';
import BackButton from '../snippets/BackButton';
import Loading from '../snippets/Loading';
import Intro from '../snippets/Intro';

function Coop() {
  return (
    <div className="coop" style={{height:window.innerHeight+'px'}}>
      <BackButton />
      <Loading />
      <Intro
				name= "코옵"
				title= "- 서울 워크쓰루"
        artistIntro= {`
          코옵은 게임문화를 연구하는 박이선과 이미지를 연구하는 권태현이 함께 작업할 때 쓰는 이름입니다. 우리는 일상의 가장 사소한 것들이 가진 예술적 가능성을 끌어내는 프로젝트를 기획합니다. 코옵은 서로 다른 것들이 만나는 순간에 발생하는 힘에 대해서 누구보다 잘 알고 있습니다.
        `}
        projectDescription= {`
          게임에서 공략을 뜻하는 말로 쓰이는 ‘워크쓰루’는 정해진 길을 미리 가본 사람의 이야기입니다. 그 길을 따라가면 가장 빠르게 목적지에 도달할 수 있죠. 현실에서는 알고리듬으로 목적지까지 가장 빠른 길을 시뮬레이션해주는 내비게이션이 마치 미리 가본 것처럼 우리에게 길을 알려줍니다. 게임에서 공략을 보기 싫어하는 사람도 내비게이션은 거리낌 없이 이용하는 경우가 많습니다. 현실에서는 누구도 모험을 하고 싶어하지 않으니까요. 〈서울 워크쓰루〉는 모두가 스피드런처럼 살고 있는 서울을 배경으로 공간화된 시간, 정해진 길과 목적성, 데이터베이스와 오프라인을 꼬아내는 텍스트 어드벤처 게임입니다. 가야 할 곳도, 목적도 없는 서울에서의 모험은 대체 무엇이 될 수 있을까요.
        `}
			>
			</Intro>
      <iframe className="coop__iframe" src="https://leesunpark.github.io/seoul-walkthrough">
      </iframe>
    </div>
  );
}

export default Coop;