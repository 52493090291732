import Figure from '../assets/hyojae_intro_figure.png';
import '../styles/hyojae.scss';
import BackButton from '../snippets/BackButton';
import Loading from '../snippets/Loading';
import Intro from '../snippets/Intro';

function Hyojae() {
  return (
    <div className="hyojae" style={{height:window.innerHeight+'px'}}>
      <BackButton />
      <Loading />
      <Intro
				name= "김효재"
				title= "- Be the Reds!"
				artistIntro= {`
          김효재는 신인류의 디폴트, '메타 클론'과 인간 간의 데이터 전이를 비디오, 퍼포먼스를 통해 고찰하는 현대미술 작가이다. 모든 것이 데이터로 환원되는 과도기적 현실에서 '디폴트' 라는 탈진실적 물음을 지속해오며 삶과 죽음, 통념과 질서, 진실과 탈진실, 가상과 현실 등이 존재하는 이전의 역사를 파괴하고 새로운 정체성의 방향을 꾸준히 제시해왔다. Set Up Your Profiles(코리아나 미술관, 2021), Follow, Flow, Feed 내가 사는 피드 (아르코미술관, 2020) , Ghost Coming 2020 &#123;X-Room&#125; (일민미술관, 2020) 등 다수의 전시에 참여했으며, 아티스트 레지던시로 NARS Foundation(뉴욕, 2022)을 예정 중이다.
        `}
				projectDescription= {`
          사운드 프로젝트 Be the Reds! 는 2022년 11월 카타르에서 열릴 예정인 FIFA 월드컵에 참가하는 대한민국의 응원가를 제작한다는 가정 하에, 이를 위한 리서치 과정을 일부 공개한다. 응원가의 레퍼런스로 사용 중인 AI 웹사이트 ‘<a href="https://lyricjam.ai/" target="_blank"/>Lyricjam</a>’는 작곡가가 악기를 연주하는 동안 시스템이 마이크를 통해 오디오 스트리밍을 듣고 재생 중인 음악에 맞게 실시간으로 가사를 생성하는 작곡 프로세스를 지원한다. 이 레퍼런스를 기반으로 내가 작곡한 응원가에 작사를 하는 AI의 실체 그리고 그/그녀/그것의 알고리즘에서 파생되는 이 가사가 가지는 감정에 대하여 고찰하고자 한다. 이 응원가는 사운드 플랫폼 ‘Prenatal Care Center(@prenatal_care_center)’을 통하여 추후 공개할 예정이다.
        `}
			>
			</Intro>
      
      <div className="hyojae__about-container">
        <div className="hyojae__about-txt-container">
          <video controls className="hyojae__video">
            <source
              src="/be_the_reds.mp4"
              type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
          </video>
          <div className='hyojae__about-title'>
            <h1 className='hyojae__about-title-txt'>
              <b>Seoul Ambush Television : Home-made</b>
            </h1>
            <h2>김효재</h2>
            <br />
          </div>
          <h3 className="hyojae__h3">
            <b>Where are you from?</b>
          </h3>
          <p className="hyojae__p">
            지독한 말이다. 나는 이 말을 들을 때마다 흠칫한다. 약간의 불쾌감, 분노와 당혹감. 이 말을 건넨 사람은 중년의 백인 여성이었고, 나의 미술사 선생님이었다. 그녀는 내 답을 듣자 또 한번 나에게 웃으며 말했다. '이 작가에 관한 미술사는 너에게 처음일 것이라 이해하기 힘들 것이다.'
          </p>
          <p className="hyojae__p">
            스크린에 비친 도판은 알폰소 무하의 작품 중 하나였고, 나는 벌떡 일어나서 ‘나는 당신이 생각하는 것보다 아주 잘 알고 있습니다. 이 작가는 서울의 한 미술관에서 몇년 전 크게 전시를 열었어요. 알폰소 무하는 한국인들이 클림트 다음으로 아주 좋아하는 작가 중 한 명입니다.’라고 소리치고 싶은 욕구로 심장이 두근댔으나 그녀는 애초에 내 답변을 들으려고 그 무수한 학생들 중 한 명인 나에게 말을 건 것이 아닐 것이라는 생각이 들었다. 나는 시야가 흐려지는 것을 느끼며 멍하니 스크린만 바라보고 있었다. 
          </p>
          <p className="hyojae__p">
            인종주의에 대한 나의 특별한 감정은 어린 시절로 거슬러 올라간다. 초등학교 시절 ‘조상 찾기’라는 수업에서 나는 내 조상의 이름을 적었고, 같이 어울렸던 무리 중 한 명이 나에게 말했다. ‘쪽바리네.’ <br/>
            곧바로 다른 아이들도 나에게 ‘쪽바리’, ‘곤니치와’, ‘스시걸’ 이라고 불렀다. 그들에게 많은 이야기를 하고 싶었으나 할 수가 없었다. 나 또한 그들과 다르지 않다는 것을 보여주기 위해 다른 사람을 ‘쪽바리’라고 불렀다. 좀 더 나이가 들어서 나의 조상이 죽기 전 후손들에게 남긴 책을 보기 전까지 나는 나를 인정하지 못했고, 인정할 수 없었고, 인정하지 않았다. 나는 나를 피하는 데 숙련된 사람이 되어갔고, 인스타그램 얼굴 필터를 고르듯이 ‘토종 한국인’이라는 가면을 쓰는 데 더 안도감을 느꼈다. 
          </p>
          <p className="hyojae__p">
            한국에서 인종적으로 소수자임을 인정하는 것은 이를 부정하는 것보다 더 많은 시간이 걸린다. 아이들 사이에서의 인종주의는 ‘순수함’이라는 이름 하에 노골적으로 드러난다. 한국에서 ‘쪽바리’라는 단어는 혈연적으로 ‘토종’, ‘우리’와 같은 ‘단일민족의식’을 역으로 강조하기 위한 단어이자 산물로, 한일 간 양국의 역사를 깊이 아는 것과 그다지 상관없이 민족적, 인종적 감정을 노골적으로 보여 준다. ‘쪽바리’를 발화하며 느끼는 한국인들의 유대감, ’정’은 2002년 한일 공동 월드컵에서 최고로 강조되는 문화로, 정서로, 연대의식으로 읽힌다. 
          </p>
          <br />
          <h3 className="hyojae__h3">
            <b>한국인</b>
          </h3>
          <p className="hyojae__p">
            Be The Reds! 
          </p>
          <p className="hyojae__p">
            2002년 여름이었다. 나는 ‘Be the Reds!’라는 슬로건이 새겨진 붉은 옷을 입고 부모님과 함께 모 대학교 인문소극장의 대형 스크린 앞에 서 있었다. 그곳엔 나와 같은 옷을 입은 붉은 사람들이 있었고, 스크린에서는 2002년 한일 월드컵이 생중계되고 있었다. 초등학생이었던 나의 주위엔 광기의 얼굴을 가진 어른들이 서로 몸을 밀착하고 엉겨 붙어서 나에게 하나의 거대한 붉은 핏덩이처럼 보였다.
          </p>
          <p className="hyojae__p">
            핏덩이가 숨을 한번 들이켰다 내쉬며 한번에 소리친다. 
          </p>
          <p className="hyojae__p">
            대~한~민~국~
          </p>
          <p className="hyojae__p">
            꿈✩은 이루어진다
          </p>
          <p className="hyojae__p">
            오 필승! 코리아 
          </p>
          <p className="hyojae__p">
          4년에 한 번씩 열리는 FIFA 월드컵은 1930년 우루과이에서 처음 시작됐다. 개최국은 거의 유럽이나 아메리카 국가들이었고, 월드컵 개최 역사 속에서 두 국가가 주관하는 공동 개최는 전례 없는 일이었다. 2002년 한일 공동 월드컵은 국가 간 오랜 역사적 감정을 축적해 온 한국과 일본이 공동으로 개최한 월드컵이다. 그러나 이 공동 개최는 양국이 역사적 감정을 해소하기 위해서가 아니라 남아메리카의 브라질, 아르헨티나, 우루과이 국가 간 갈등으로 인하여 엉겁결에 얻은 결과다.
          </p>
          <p className="hyojae__p">
            역사 속 아르헨티나-브라질 전쟁은 1825년부터 1828년까지 시스플라티나 주의 독립 문제를 놓고 아르헨티나와 브라질 간에 벌어진 전쟁이다. 당시 브라질 제국이 포르투갈 왕국으로부터 독립한 뒤, 1921년 지금의 우루과이인 시스플라티나를 합병하였다. 이에 시스플라티나 주의 주민들이 브라질의 통치에 반대하여 아르헨티나의 지원을 받아 독립을 선언했고, 브라질과 아르헨티나는 500일 해상 전쟁을 치뤘다. 이 전쟁의 결과로 시스플라티나 주가 브라질로부터 독립하고, 신생국인 우루과이가 건국됐다. 전쟁은 아르헨티나, 우루과이의 승리로 끝이 났고 이러한 역사적 배경으로 인하여 이후 브라질과 아르헨티나는 항상 관계가 좋지 못했다.
            <a href='#f1'>
              <sup className='hyojae__footnote' id="p1">1</sup>
            </a>
          </p>
          <p className="hyojae__p">
            1888년 브라질에서 노예제도가 폐지되자 부족한 노동력을 보충하기 위해 이민을 적극적으로 받아들였고, 이에 일본은 1908년부터 브라질로 이민자를 보내기 시작했다. 그러면서 브라질과 일본의 국가 관계는 자연스럽게 우호적 분위기가 형성되었고, 당시 개최국의 대부분이 유럽이나 아메리카 국가들임에도 불구하고 브라질 출신의 FIFA 회장 아벨란제는 2002년 월드컵을 일본에서 개최하고자 했다. 일본은 1989년 월드컵을 개최하겠다는 공식 입장을 발표, 2002년 월드컵 개최를 본격적으로 준비했다. 그러나 한국 또한 1988년 서울올림픽을 성공적으로 개최하였기에 월드컵 개최 의사를 공식적으로 FIFA에 입장을 밝혔고, 이후 1993년 14대 대통령 김영삼이 당선되면서 내건 성공적인 월드컵 유치 공약과 1994년 FIFA 부회장으로 당선된 정몽준 회장이 개최국 결정 투표권을 가지게 되면서 1995년 개최국이 한국이냐 일본이냐로 나뉘 됐다. 
            <a href='#f2'>
              <sup className='hyojae__footnote' id="p2">2</sup>
            </a>
          </p>
          <p className="hyojae__p">
            당시 FIFA 회장 주앙 아벨란제와 브라질 축구 선수 펠레는 일본을 지지, 유럽 축구 연맹(EUFA) 회장 렌나르트 요한손과 아르헨티나 축구 선수 디에고 마라도나, 그리고 우루과이는 한국을 지지하며 월드컵 개최국 선정이 남미 국가 간의 감정으로 인한 패권 싸움으로 번지게 되었다. 상황이 악화되자 일본 측에서 먼저 공동 개최를 제안했고, 한국은 일본과 함께 2002년 6월 제17회 월드컵을 개최하였다.
          </p>
          <p className="hyojae__p">
            한국 vs 일본. 소위 ‘한-일’ 전은 한국과 일본 간의 역사적 감정에 근거하여 만들어진 문화이다. 대외적으로 이 문화적 감정은 스포츠에 주로 이용이 되고, 한국은 스포츠 속 ‘정’이라는 문화를 앞세워 단일 민족 의식, 한민족 문화의 결속을 단단히 다져왔다.
          </p>
          <p className="hyojae__p">
            인종주의에 의한 차별은 피부색, 성별, 종교뿐만 아니라 문화에 따라서도 발생한다. 한국은 ‘정’이라는 문화 안에 한국인이라는 인종에 특별한 의미를 부여하나, 그 사회 안으로 들어가면 갈수록 유교적, 정치적, 역사적 의의를 앞세워 소수자의 감정과 차별에 미세한 선을 긋는다. 
          </p>
          <p className="hyojae__p">
            ‘Be the Reds!’의 대표 컬러인 레드(Red)를 두고 일각에서는 공산주의를 지지하는 빨갱이들의 결집을 유도한다 혹은 한민족 정서의 유대감으로 일본에 대한 승리 의식을 고취한다에 대한 같은 갑론을박이 있었지만, 나는 정확한 언어를 획득하지 못했다. 그저 ‘쪽바리’가 되기 싫어서 ‘빨갱이’가 되었고, ‘빨갱이’가 되었지만 절대 ‘토종’이 될 수 없음에 오히려 나의 소수자적 감정은 ‘전혀 아무것도 느낄 수 없는 상태’이다. 나는 ‘한국인’ 그 이상으로 ‘토종’이 되어야 했다. ‘토종’이라는 단 하나의 피, 단 하나의 붉은 색은 내가 절대 가질 수 없는 나의 집이자, 내가 무수한 레즈(Reds) 중 한 명의 레드(Red)가 될 수 없게 만드는 장벽이다. 
          </p>
          <p className="hyojae__p">
            여권 상 국적은 'Republic of Korea'지만 ‘쪽바리’인 한국인. 그 얼굴은 무엇일까? ‘토종 한국인’이라는 가면을 쓴 나는 서구 세계에서 그저 ‘동양인 여성’ 일 뿐이다. ‘쪽바리’는 곧 ‘칭챙총’으로 바뀌었고, 분노가 솟구치는 걸 어쩌지 못했다. 그러면서 나는 이제 더 이상 무슨 말을 해야 할지 모르겠는, 도대체 알 길이 없는 먹먹한 상황에 처하며 내가 나를 설명하기 이전에 알 수 없는 공식이 존재함을 느꼈다.
          </p>
          <br />
          <h3 className="hyojae__h3">
            <b>서울인</b>
          </h3>
          <p className="hyojae__p">
            나는 1993년 포항에서 태어났다. 3살 때 서울 강동구의 유치원을 잠깐 다녔고, 경상남도 창원의 유치원과 초등학교 2학년을 다녔고, 서울 동작구에서 초등학교 3학년까지 보내고 1년 뒤 강남 8학군으로 이사하여 줄곧 있었다. 
          </p>
          <p className="hyojae__p">
            서울에 대한 나의 첫 기억은 꽤나 명확하다. ‘사투리’. 부모님은 내가 경상남도에 살 때, 서울에서는 안 쓰던 경상도 사투리를 쓰기 시작하는 것을 보고, 다시 서울로 올라갈 것을 다짐했다. 단일 언어 ’한국어’를 쓰는 한국인들 사이에서도 톤과 억양을 듣고 서울 혹은 서울 밖을 규정짓는 -미세하다 못해 진절머리 나는- 기준은 ‘언어’ 였다. 서울인이 쓰는 언어가 바로 한국인의 표준 언어고, 서울인이 쓰는 말은 세련됐다는 판타지는 부모님 세대에게 뿌리 박혀 있었고, 이 판타지가 교육열이 높았던 부모님을 서울로 올라오게 만든 원동력이 되었다. 
          </p>
          <p className="hyojae__p">
            나의 어린 시절은 대체로 아주 희미해서 기억의 시작은 강남 8학군 시절에서부터다. 부모님은 최고의 교육 환경에서 나를 공부시키기 위해 서울의 강남 8학군으로 이사를 했다. 중간고사와 기말고사가 끝나면 강남역의 노래방에서 빅뱅 노래를 불렀고, 스티커 사진을 찍고 레드망고에서 아이스크림을 먹었다. 미술대학 입시를 위해 선릉역 근처 미술학원을 다녔고, 남부터미널역 근처에서 대학 재입학 시험 준비를 했다. 
          </p>
          <p className="hyojae__p">
            20살 때 대학교 동기에 이끌려 광화문의 이순신 동상을 처음으로 보았었다. 당시 동기는 내가 무슨 말을 하건 행동을 하건 나를 “강남키드라서 그렇다”라고 놀려댔는데 사실 이제 막 입시를 뗀 내가 할 수 있는 말은 그다지 많지 않았다. 학교, 시험, 노래. 클래식을 좋아한다고 하면 강남 키드라 재수 없다는 소리를 들은 이후로 애니메이션을 보고, 인디 밴드 음악을 들었다. 왜 애니메이션의 여성은 남성의 자위를 위한 몸으로 소비되어지고, 왜 인디밴드는 여성을 스토킹하는 쓰레기 같은 가사를 부르는지에 대한 역겨움을 느꼈지만 속으로 삼켰다. 나를 설명하기도 전에 강남키드라는 공식에 나를 끼워맞추는 그들과 섞이고 싶어서. 또 한번 나는 많은 이야기를 하고 싶었으나 할 수가 없었다. 나는 나를 설명하기도 전에 자기 부정을 해야 했고, ‘예의' 상 자기 혐오가 들어간 이야기도 스스로 해야 했었다. 그래야 “부자동네에 사는 여자치고 겸손하고 온순하다.” 라는 피드백이 돌아왔고, 체중계에 올라서는 수치심을 덜 느낄 수 있었다. 나는 나라는 몸무게를 저울질 하는 사람들 주변에서 폭풍처럼 지냈고, 서울 주변에서 서울을 바라보았다. 나는 서울을 균열내고 싶어했다. 
          </p>
          <p className="hyojae__p">
            이것이 서울에 대한 나의 큰 기억이다. 서울이 하나의 정육점이라면, 나는 이미 죽었다. 가장 질 좋은 고기로 포장하고, 썰리고, 무게를 측정 당한다. 열 살 이전까지의 나는 서울에 사는 아이었다. 그후 쪽바리가 되었고, 10대 때는 강남키드가 되었고, 성인이 되어서는 김치녀가 되었고, 30대가 된 지금은 그린카드를 받기 위해 1세계 백인 남자와의 결혼을 물색하는 동양인 여성이 되었다.
          </p>
          <br />
          <h3 className="hyojae__h3">
            <b>분홍색 코끼리 탈</b>
          </h3>
          <p className="hyojae__p">
            강남역은 서울특별시 강남구 역삼동과 서초구 서초동에 걸쳐 있는 서울 지하철 2호선과 신분당선의 전철역이자  19년 연속 ‘이용객이 가장 많은 지하철역’으로 선정되었다. ‘강남역 사거리’ 또는 ‘강남역 지하상가’ 라고도 불리며 서울의 명동, 홍대 등과 함께 손꼽히는 번화가 중 한 곳이다.
          </p>
          <p className="hyojae__p">
            2016년 강남역 10번 출구 앞에는 강남역의 노래방 화장실에서 여성을 대상으로 한 살인 사건의 추모식이 있었다. 나는 꽃을 두고 추모를 하는 동안 나의 행동을 보는 무수한 타자의 눈에 둘러싸여 나의 한계에 대하여, 나의 굴레에 대하여 생각했다. 내 존재가 발가벗겨진 기분이 들었다. 황급히 빠져나온 것 같았다. 왜냐하면 이 죽음은 내 문제고, 나의 죽음일 수도 있었고, 그래서 내가 이렇게 추모하는 행동이 내가 살아 남았다는 것을 느끼게 했고, 내 자신이 역겨웠다. 
          </p>
          <p className="hyojae__p">
            한 여성의 죽음을 두고 공방전이 벌어졌다. 여성혐오가 아니다 라고 주장하는 입장은 ‘강남’이라는 부유층 밀집 지역에 대한 특정 감정이 있다고 보며 ‘지역적 상징성’을 이야기했다. 나는 여성 혐오가 아님을 주장하는 근거인 ‘지역적 격차’에 대한 다른 살인 사건들을 찾아보았다. 그러나 내가 찾은 사건들에서 살인 가해자들이 피해자 ‘여성’이 화장실에 들어올 때까지 그 전에 동일한 화장실을 사용한 남성들을 살해하지 않았다는 사실은 찾아볼 수 없었다. 
          </p>
          <p className="hyojae__p">
            며칠 간 이뤄졌던 추모식에서 분홍색 코끼리 탈을 쓴 일베 유저가 등장했다. 그는 영화 &lt;주토피아&gt;를 인용한 내용의 피켓을 들고 있었다. 
          </p>
          <p className="hyojae__p">
            "육식동물이 나쁜 게 아니라 범죄를 저지르는 동물이 나쁜 겁니다.
          </p>
          <p className="hyojae__p">
            선입견 없는, 편견 없는 주토피아 대한민국
          </p>
          <p className="hyojae__p">
            현재 세계 치안 1위지만 더 안전한 대한민국 남녀 함께 만들어요.”
          </p>
          <p className="hyojae__p">
            영화 &lt;주토피아&gt;에서 전하는 메세지를 한 여성의 추모식에서 표출하는 그들을 바라보는 데서 떠오르는 감정은 잔인하게도 '당혹감'이다. 그 가면 아래에, 한 여성의 죽음에 대한 조의나 유대감을 자신들이 신봉하는 사상으로 짓밟아버리는 저열함은 너무나도 다수의 얼굴이다. 선입견 없는, 편견 없는 주토피아 대한민국. 세계 치안 1위의 국가 대한민국. 나는 그동안 폭풍같이 휩쓸렸던 그 레즈(Reds) 사이에서 그들과 섞이기 위한, ‘토종 한국인’이 되기 위한 시도를 그제야 그만둘 수 있었다. 
          </p>
          <p className="hyojae__p">
            강남역 10번 출구 맞은편에는 가수 싸이의 강남 스타일 동상이 버젓이 있다. 가사 속 어떤 여자, 어떤 여자, 섹시 레이디 를 나열하며 소위 ‘강남스타일 열풍’으로 만들어진 조형물 맞은 편에는 그 어떤 여자였던 한 여자의 죽음이 사람들의 기억 속에서만 존재하고 있다. 
          </p>
          <p className="hyojae__p">
            지금도 나는 강남역을 지나치는 버스 안에서, 강남CGV 영화관에서 심야영화를 보고 개찰구에 들어가는 길에서, 종종 그 여자를 생각한다. 그 여자는 나였을 수도, 그리고 나였을 것이다. 
          </p>
          <br />
          <h3 className="hyojae__h3">
            <b>Seoul Ambush Television: Aesthetic</b>
          </h3>
          <p className="hyojae__p">
            ‘미감(美感)’은 ‘아름다움에 대한 느낌’이라는 뜻의 명사이다.
            <a href='#f3'>
              <sup className='hyojae__footnote' id="p3">3</sup>
            </a>
            서울의 미감, 즉 ‘서울미감’은 ‘대한민국의 수도, 면적605.2㎢, 인구 958만 8,711명, 2019년 글로벌도시 경쟁력을 평가하는 글로벌도시지수(GCI) 종합순위 17위' 도시 서울이 가진 아름다움의 본질을 파헤치지 않는다. ’’서울미감’은 모두가 미래를 향해 돌진하는 와중에 뒤를 돌아 보는 누군가의 본질이며, 정육점에서 무게 측정을 기다리고 있는 고깃덩어리의 마블링 정도일 뿐이다. 
          </p>
          <p className="hyojae__p">
            ‘Ambush television’은 텔레비전 쇼에 출연한 게스트에게 호스트와 관중들이 의도적으로 모의하여 만든 장난을 치고 이에 당혹감을 느끼는 게스트를 촬영하는 몰래카메라 형식의 방송을 지칭한다. 넷플릭스 다큐멘터리 &lt;미디어 재판&gt;의 1화 죽음의 토크쇼에서 선정성으로 악명 높은 ‘제니 존스 쇼’가 바로 이 형식을 차용하는데, 이로 인해 살인사건이 발생한다. 타인의 고통을 보며 안도감을 느끼는 샤덴 프로이데 심리와 같이, 우리는 자신이 속한 세계관의 ‘망가짐 혹은 일그러짐’을 보는 것을 즐기거나 의도적으로 타인의 ‘가치평가 절하’에 대한 욕망을 가진 채 살아간다. 
          </p>
          <p className="hyojae__p">
            서울은 나에게 앰부쉬 텔레비전(Ambush television) 과 같다. ‘서울’이라는 텔레비전 쇼에 고정 게스트로 출연하고, 예상치 못한 그의 악의적인 혹은 초라한 장난에 매번 당황함을 느낀다. 이를 지켜보는 관중들과 시청자는 이 장난을 미리 알거나 알면서도 모르는 척하거나 몰랐지만 비로소 알게 되어서 신이 나거나의 교집합 일 것이다. 일생에 단 한 번 위험한 상황에 처할 때 자신의 꼬리를 잘라내고 도망치는 도마뱀처럼, 나는 지금까지 이들로부터 도망치기를 시도했고 몇 번의 실패 끝에 2021년 나의 일부를 잘라내고서야 서울로부터 도망칠 수 있었다.
          </p>
          <p className="hyojae__p">
            무엇이 나로 하여금 스스로의 일부를 잘라내게 만들었을까? 왜 나는 스스로를 잘라내지 않으면 도망칠 수 없었을까? 그것은, 나를 납작하게, 무기력하게 만드는 서울은, 바로 해상도 높은, ‘풍자’라는 가면 아래 숨기는 저열함 그러면서 반전매력에 집착하는 ‘가성비’였다. 단연코 ‘가성비’는 곧바로 ‘서울의 미감’을 의미한다. 
          </p>
          <p className="hyojae__p">
            ‘가성비’는 “가격 대비 성능(價格對比性能, price–performance ratio, cost–performance, cost–benefit)"의 준말로, “소비자 혹은 고객이 지불한 가격에 비해 제품이나 서비스의 성능이 소비자나 고객에 얼마나 큰 효용을 주는지 나타내는” 척도이다.
            <a href='#f4'>
              <sup className='hyojae__footnote' id="p4">4</sup>
            </a>
            우선, 가성비는 비율이 아니라 미감처럼 작동한다. 그리고 그것은 수많은 미감을 고려하고 선별하여 도출된 선택이 아니라, 일그러진 절약정신에서 파생된 '더 납작하게!'를 외치는 미감이다. 가성비는 개인 고유의 미감을 부정하고 결속과 단견을 중시한다. ‘편의점 맥주 4캔에 만원’과 같은 표면적인 가성비뿐만 아니라 먹방을 보며 식욕에 대한 대리만족을 느끼는 감정 또한 가성비라고 할 수 있다. 1+1, 브랜드의 억지스러운 토속적 콘셉트, 365일 친절한 고객센터 등은 가성비를 통해 상품에 대한 가치 절하에 대한 욕망과 ‘망가짐 혹은 일그러짐’을 통한 안도감을 여실히 드러낸다.
          </p>
          <p className="hyojae__p">
            코로나19로 변화를 겪은 서울은 가성비 미감을 살피기 좋은 도시다. 가장 두드러진 모습은 코로나 이후 온라인 플랫폼과 테크놀로지의 중요성이 눈에 띄게 증가했다. 퍼스널 스페이스(Personal Space)에 대한 선택 유무가 전혀 존재하지 않았던 서울은 코로나 이후 소위 ‘몸’의 이동을 최소화하고 개인은 자신에게 주어진 공갼- 퍼스널 스페이스(Personal Space) 라고 지칭하나 사실상 반-격리 공간인 - 속에서 '최저의 감각에서 최고의 미감을 끌어내기', 즉 '가성비'를 영위하고 있었다. 
          </p>
          <div className='hyojae__img'>
            <img src={Figure} />
          </div>
          <p className="hyojae__figure-caption">
            자료 1. 서울연구원 온라인 설문조사 (2020년 9월 8일~15일간 서울시민 1,000명을 대상, (서울인포그래픽스, 제308호 “코로나19가 바꾼 시민생활”, 2020.10.12, 서울연구원) 
          </p>
          <p className="hyojae__p">
            실제 코로나 이후의 서울의 변화와 미래를 다룬 여러 자료를 살펴보았을 때, 그 대표적인 결과는 유동인구 감소로 인한 소상공인, 음식점과 소매업 매출 감소와 기후 위기 및 환경 오염에 대한 관심 증대로 좁혀진다. 코로나19 이후 생활권 단위는 10분 안에 갈 수 있느냐 없느냐의 여부를 고려할 정도로 물리적 이동이 대폭 감소했고, 그 대신 재택근무, 온라인 교육, 무인 주문 시스템이 증가했다. 이런 상황에서 우리는 자연스럽게 '몸'의 감각이 확장되는 것이 아니라 최소한의 감각에 집중하게 된다. 악수나 포옹 같은 물리적 촉각이 아니라 스크린 '몸'을 바라보는 시각으로 축소된다는 것이다. 앞서 말했던 것처럼 감각의 다양성, 다층성, 풍부함은 사라지고 모든 것이 시각으로 수렴되는 이 현상도 가성비 미감이 작동한 결과라고 볼 수 있지 않을까?
            <br/>
            팬데믹 시대의 온라인 속 '몸' 의 가성비는 테크놀로지를 손쉽게 이용하는 이들에게 '닭이 먼저냐, 계란이 먼저냐' 의 싸움일 것이다. 우리는 온라인 상에서의 몸과 현실 상에서의 몸의 일치에서 오는 불협화음에 혼란을 느끼고 있다.
          </p>
          <h4 className="hyojae__h4">
            격리 일지 1. 2021.09.13.
          </h4>
          <p className="hyojae__p">
            암스테르담에서 환승한 밤 비행기가 인천에 상륙했다. 사람들은 격리 시 필요한 추적어플을 다운로드 받고 있었고, 곧바로 입국심사대에서 몇 가지 서류들과 함께 어플 작동 여부와 자가격리에 대한 메뉴얼을 설명해 주었다. 그들은 내가 수용할 수 있는 선보다도 더 많은 정보와 어색함, 살짝의 허술함으로 인한 당혹감을 나에게 선사했고, 곧바로 서울 바깥에 나와 함께 머물던 1년 치의 모든 것들은 호송 콜밴에 옮겨져 이동했다. 숙소에 도착하기 전 보건소에 들려 올해 들어 7번째 촉각 고통을 체감했고, 핸드폰에는 지울 수 없는 파란 이모지(Emoji)만이 나에게 머물러 있었다. 숙소에 도착하고 바로 배달음식을 시켰다. 
          </p>
          <h4 className="hyojae__h4">
            격리 일지 2. 추석
          </h4>
          <p className="hyojae__p">
            ‘침묵’에 대해서 생각을 한다. 바깥은 시끄럽고, 사람들은 밤 10시면 끝날 즐거움을 위해 모두들 매일 최선을 다한다. 나는 마치 이름 모를 섬의 해수면 밑에 잠겨 섬 위의 밝고 활기찬 소리를 조용히 듣는다. 시차로 인한 잠의 고통 속에서 그들의 시간과 나의 시간은 거꾸로 흘러가고, 나는 서울을 가장 먼저 소리로 체감한다. 그들의 부산스러운 움직임과 나의 수면은 만날 수 없지만 어쩐지 비정상적으로 교차한다. 대략 오후 4시부터 10시에 끝나는 유흥 문화는 시간과 자극에 대한 사람들의 가성비가 스며들어 순간의 감정에 과몰입하게 만든다. 느슨한 감시 속에서 사람들은 ‘정해진 시간 속에서 얼마만큼의 자극을 취득할 수 있는가’에 대한 몰입을 시도한다. 
          </p>
          <p className="hyojae__p">
            격리를 하면서 유튜브 영상을 자주 보았다. 한국은 모든 것이 콘텐츠로 귀결된다. 심지어 추석이라는 오랜 역사를 지닌 한국의 연휴 이벤트조차도 ‘전 100개 부치기’와 같은 아주 가벼운 사탕처럼 치부된다. 예전에 나는 서울이라는 도시에 대한 납작한 미감만을 논해왔었다. 이제 이곳에 사는 사람들은 스스로를 그 납작함 속에 내던지고 오히려 납작해지기를 원한다. 이상하리만치 자신의 모든 일상생활을 불특정 다수에게 공유를 하며, 사람들의 눈이 자기 자신에게만 머물기를 바란다. 
          </p>
          <h4 className="hyojae__h4">
            격리 일지 3. 2021.09.28.
          </h4>
          <p className="hyojae__p">
            코로나19는 서울의 가성비 미학이 비물리적 차원에서 어떻게 작동하는지를 보여 주었다. 나는 타인을 만지는 것보다 키보드 자판을 만지는 감각을 기억하며, 바닥의 굴곡을 느끼기 보다 눈에 보이지 않는 선을 붙잡으려 애쓴다. 
          </p>
          <p className="hyojae__p">
            ‘휴먼(Human)’은 시청률을 위한 하나의 자극적인 데이터일 뿐이다. 서울의 '휴먼(Human)' 또한 가성비를 위한 친숙한 데이터이자 미감이고, 꼬리를 자른 도마뱀은 다시 새로운 꼬리를 가진다. 우리는 이 꼬리의 형태가 이전과 동일할 것이라는 확신을 가질 수도, 어떤 형태로 다시 자라나는지에 대한 희망도 가질 수 없다. 그 형태는 이전과 단절된 새로운 형태일 것이고, 우리는 그 순간을 목도하기 위해 현재를 살아가며 기다려야 한다. 
          </p>
          <p className="hyojae__p">
            가성비가 익숙해진 세대와 그 가성비를 쫓아가는 나머지 세대들 모두 미래를 향해 걸어간다. 
          </p>
          <br />
          <h3 className="hyojae__h3">
            <b>Seoul Ambush Television: 망명객</b>
          </h3>
          <p className="hyojae__p">
            고모는 영화 시나리오 작가이자, 역사콘텐츠학자이다. 고모는 모든 상황을 객관적으로 보려 하고, 역사적 인물에 대한 연구 시 자신의 친인척은 객관화시킬 수 없어서 피해야 한다고 했다. 고모는 나와 동일한 혈연적 조상에 대하여 입을 열지 않은 나와는 달리 자신이 객관적으로 특이한 존재가 된 것 같아 스스로 신기해했다고 했다. 
          </p>
          <p className="hyojae__p">
            고모와 나는 22살에 갈 수 없는 나라를 가진 한 망명객에 대한 이야기를 했다. 북한에서 탈출한 탈북자, 정치적 이슈로 남미, 러시아 그리고 중국에서 미국으로 망명한 지식인들 그리고 한국의 독립투사들과 함께 자연스레 고모의 시어머니도 등장했다. 올해 90세이신 시어머니는 6.25 한국전쟁이 끝나고 난 뒤, 남한으로 귀화한 실향민이다. 지금도 자신이 다녔던 유치원 근처 대동강이 선연히 떠오르신다고 하면서, 반면에 50세의 고모는 언제든지 고향에 갈 수 있고, 자신의 어린 시절은 전혀 기억이 나지 않는다고 했다. 고모는 고향의 체제는 싫지만 그 고향 땅이 그립고, 못 가니까 더 가고 싶고, 못 보니까 더 보고 싶은 거라서 소중하기 때문에 자신의 소회를 생생하게 기억하려고 하는 것이라 했다. 
          </p>
          <p className="hyojae__p">
            나는 집에 있어도 집에 가고 싶은 마음처럼, 서울에 있어도 서울로 돌아가고 싶다. 나는 서울의 망명객으로 자라왔으면서도 서울에 내가 갈 수 없음에 대한 의구심을 품고 있다. 나는 서울에 살면서 서울이 그립고, 못 가니까 더 가고 싶고, 못 보니까 더 보고 싶어서 서울을 자세히 들여다보기 시작했다. 
          </p>
          <br />
          <h3 className="hyojae__h3">
            <b>불면증</b>
          </h3>
          <p className="hyojae__p">
            이 글은 전혀 유쾌하지 않고, 깨끗하지도 않다. 누군가는 이 글을 보고 특권 의식을 가진 자의 푸념으로, 서울과 한국에 대한 편협한 시선에 기대는 글로 볼 수도 있다. 그러나 서울에 살든, 서울 밖에서 살든, 서울 안에서 죽든, 나는 결코 서울인이 될 수도, 한국인이 될 수도 없다. 소속감의 결핍은 곧바로 내가, 나로 하여금, 나의 정체성(identity)에 대한 집착으로 이어졌고, 그럼에도 나는 말을 꺼낼 수밖에 없었다. 역사는 객관적이어야 하지만, 그 역사를 만드는 인간은 로봇이 아니고, 그렇기에 역사는 절대적으로 객관적일 수 없다고 본다. 개인의 역사는 곧 사회를 반영하는 산물이며, 그럼에도 내가 그럼에도 느끼는 이 당혹감은 결코 사소하지 않다. 과거를 돌아보는 일은 현재를 재생산하며, 나를 찾아가는 과정은 내가 두 발 딛고 서 있는 곳, 서울에서조차도 일어난다.
          </p> 
          <p className="hyojae__footnote-p" id="f1">
            <a href='#p1'>
              <sup className='hyojae__footnote'>1</sup>
            </a>
            “아르헨티나-브라질 전쟁”, 「위키백과: 우리 모두의 백과사전」, 2020.7.4.,
            <a href="https://ko.wikipedia.org/wiki/%EC%95%84%EB%A5%B4%ED%97%A8%ED%8B%B0%EB%82%98-%EB%B8%8C%EB%9D%BC%EC%A7%88_%EC%A0%84%EC%9F%81" className="hyojae__footnote-a" target='_blank'>
              Link↗︎
            </a>
          </p>
          <p className="hyojae__footnote-p" id="f2">
            <a href='#p2'>
              <sup className='hyojae__footnote'>2</sup>
            </a>
            ⓒ지식의 정석, 2002년 월드컵, 한국과 일본만 공동으로 개최했던 진짜 이유, 지식의 정석,
            <a href='https://principlesofknowledge.kr/archives/195802' className="hyojae__footnote-a" target='_blank'>
              Link↗︎
            </a>
          </p>
          <p className="hyojae__footnote-p" id="f3">
            <a href='#p3'>
              <sup className='hyojae__footnote'>3</sup>
            </a>
            “미감”, 국립국어원 표준국어대사전, 2021.12.03., 
            <a href='https://stdict.korean.go.kr/search/searchView.do' className="hyojae__footnote-a" target='_blank'>
              Link↗︎
            </a>
          </p>
          <p className="hyojae__footnote-p" id="f4">
            <a href='#p4'>
              <sup className='hyojae__footnote'>4</sup>
            </a>
            “가격대비성능”, 「위키백과: 우리 모두의 백과사전」,  2020.04.17., 
            <a href='https://ko.wikipedia.org/wiki/%EA%B0%80%EA%B2%A9%EB%8C%80%EB%B9%84%EC%84%B1%EB%8A%A5' className="hyojae__footnote-a" target='_blank'>
              Link↗︎
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Hyojae;