import { useEffect, useState } from 'react';
import '../styles/loading.scss';

function Loading() {
	const [gif, setGif] = useState('../assets/load_ani.gif');

	useEffect(() => {
		var ranNum = Math.random();
		if (ranNum>0.5){
			setGif('../assets/load_ani.gif');
		}else{
			setGif('../assets/load_ani2.gif');
		}
	}, [gif]);

	return (
		<div className='l__loading-screen z-sub-nav'>
			<div className="l__load">
				<img src={gif} className='l__load-img'/>
				<div className='l__load-bar'>
					<svg
						viewBox="0 0 360 360"
						xmlns="<http://www.w3.org/2000/svg>"
						className='l__load-ring'
					>
						<circle
							className='l__main'
							cx="180" cy="180" r="170"
							stroke-width="10" stroke="#0600ff"
							fill="none"
						/>
					</svg>
				</div>
			</div>
		</div>
	)
}

export default Loading;