import { useRef } from 'react';
import '../styles/sima-movie.scss';
import BackButton from '../snippets/BackButton';
import Loading from '../snippets/Loading';

function SimaMovie() {
  const seoul2121Video = useRef<HTMLVideoElement>(null);
  return (
    <div className="sima-movie" style={{height:window.innerHeight+'px'}}>
      <BackButton />
      <Loading />
      <video controls autoPlay ref={seoul2121Video} className="sima-movie__video">
        <source
          src="https://lapses.s3.ap-northeast-2.amazonaws.com/seoul-aesthetic/seoul2121_trailer.mp4"
          type="video/mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
    </div>
  );
}

export default SimaMovie;