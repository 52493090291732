const ActivatableSingleton = {
  SELECTORS: {
    toggler: '[data-activatable-singleton-toggler]',
    activator: '[data-activatable-singleton-activator]',
    deactivator: '[data-activatable-singleton-deactivator]',
    toggleableItem: '[data-activatable-singleton-togglable-item]',
  },
  ATTRIBUTES: {
    toggler: 'data-activatable-singleton-toggler',
    activator: 'data-activatable-singleton-activator',
    deactivator: 'data-activatable-singleton-deactivator',
    toggleableItem: 'data-activatable-singleton-togglable-item',
    id: 'data-activatable-singleton-id', // Same id needs to be added on toggler and toggleable item. this needs to be unique in the singleton group.
    active: 'data-active',
  },

  init() {
    const togglers = [].slice.call(
      document.querySelectorAll(ActivatableSingleton.SELECTORS.toggler),
    );
    const activators = [].slice.call(
      document.querySelectorAll(ActivatableSingleton.SELECTORS.activator),
    );
    const deactivators = [].slice.call(
      document.querySelectorAll(ActivatableSingleton.SELECTORS.deactivator),
    );
    const toggleableItems = [].slice.call(
      document.querySelectorAll(ActivatableSingleton.SELECTORS.toggleableItem),
    );

    ActivatableSingleton.bindEventListeners(
      togglers,
      activators,
      deactivators,
      toggleableItems,
    );
  },

  bindEventListeners(
    togglers: HTMLElement[],
    activators: HTMLElement[],
    deactivators: HTMLElement[],
    toggleableItems: HTMLElement[],
  ) {
    togglers.forEach((toggler) => {
      const toggleId = toggler.getAttribute(
        ActivatableSingleton.ATTRIBUTES.toggler,
      );
      const currentToggleableItems = toggleableItems.filter(
        (toggleableItem) =>
          toggleableItem.getAttribute(
            ActivatableSingleton.ATTRIBUTES.toggleableItem,
          ) === toggleId,
      );
      const currentTogglingItems = currentToggleableItems.filter(
        (togglingItem) =>
          togglingItem.getAttribute(ActivatableSingleton.ATTRIBUTES.id) ===
          toggler.getAttribute(ActivatableSingleton.ATTRIBUTES.id),
      );

      if (!toggleId && !currentToggleableItems) return;

      toggler.addEventListener('click', () => {
        if (ActivatableSingleton.isActive(currentTogglingItems[0])) {
          ActivatableSingleton.deactivate(currentToggleableItems);
        } else {
          ActivatableSingleton.activate(
            currentToggleableItems,
            currentTogglingItems,
          );
        }
      });
    });

    activators.forEach((activator) => {
      const activatorId = activator.getAttribute(
        ActivatableSingleton.ATTRIBUTES.activator,
      );
      const currentActivatorItems = toggleableItems.filter(
        (toggleableItem) =>
          toggleableItem.getAttribute(
            ActivatableSingleton.ATTRIBUTES.toggleableItem,
          ) === activatorId,
      );
      const currentActivatingItems = currentActivatorItems.filter(
        (actiavatingItem) =>
          actiavatingItem.getAttribute(ActivatableSingleton.ATTRIBUTES.id) ===
          activator.getAttribute(ActivatableSingleton.ATTRIBUTES.id),
      );

      if (!activatorId && !currentActivatorItems) return;

      activator.addEventListener('click', () => {
        ActivatableSingleton.activate(
          currentActivatorItems,
          currentActivatingItems,
        );
      });
    });

    deactivators.forEach((deactivator) => {
      const deactivatorId = deactivator.getAttribute(
        ActivatableSingleton.ATTRIBUTES.id,
      );
      const currentDeactivatorItem = toggleableItems.filter(
        (toggleableItem) =>
          toggleableItem.getAttribute(ActivatableSingleton.ATTRIBUTES.id) ===
          deactivatorId,
      );

      if (!deactivatorId && !currentDeactivatorItem) return;

      deactivator.addEventListener('click', () => {
        ActivatableSingleton.deactivate(currentDeactivatorItem);
      });
    });
  },

  isActive(currentToggleableItem: HTMLElement) {
    return currentToggleableItem.hasAttribute(
      ActivatableSingleton.ATTRIBUTES.active,
    );
  },

  deactivate(currentToggleableItems: HTMLElement[]) {
    currentToggleableItems.forEach((currentToggleableItem) => {
      currentToggleableItem.removeAttribute(
        ActivatableSingleton.ATTRIBUTES.active,
      );
    });
  },

  activate(
    currentToggleableItems: HTMLElement[],
    currentTogglingItems: HTMLElement[],
  ) {
    const sanitizedCurrentToggleableItems = currentToggleableItems.filter(
      (item) =>
        currentTogglingItems[0].getAttribute(
          ActivatableSingleton.ATTRIBUTES.id,
        ) !== item.getAttribute(ActivatableSingleton.ATTRIBUTES.id),
    );

    ActivatableSingleton.deactivate(sanitizedCurrentToggleableItems);
    currentTogglingItems.forEach((currentTogglingItem) => {
      currentTogglingItem.setAttribute(
        ActivatableSingleton.ATTRIBUTES.active,
        'true',
      );
    });
  },
};

export default ActivatableSingleton;
