import { useEffect } from 'react';
import '../styles/intro.scss';
import Activatable from "../scripts/activatable";
import ActivatableSingleton from "../scripts/activatableSingleton";

export const Modules = [
  Activatable, 
  ActivatableSingleton
];

const Intro = (props: any) => {
  useEffect(() => {
    Modules.forEach((module) => module.init());
  }, []);

  const createMarkup = (p: string) => {
    return {__html: p};
  }

  return (
    <div className='intro__artistIntro z-4'>
      <div className='artistName'>
        <button
          className="artistTextButton"
          data-activatable-toggler="artist"
        >
          <h3 className="hwi__text-h3">{props.name}</h3>
        </button>
      </div>
      <div
        className='intro__artistText hidden'
        data-activatable-toggleable-item="artist"
        dangerouslySetInnerHTML={createMarkup(props.artistIntro)}
      >
      </div>
      <div className="intro_project mt-2xs">
        <button
          className="projectDescriptionButton"
          data-activatable-toggler="project"
        >
          <h3
            className="hwi__text-h3"
            dangerouslySetInnerHTML={createMarkup(props.title)}
          >
          </h3>
        </button>
        <div
          className='intro__projectDescription hidden'
          data-activatable-toggleable-item="project"
          dangerouslySetInnerHTML={createMarkup(props.projectDescription)}
        >
        </div>
      </div>
    </div>
  )
}

export default Intro;