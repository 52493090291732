import Figure0 from '../assets/tom/0.jpg';
import Figure1 from '../assets/tom/1.jpg';
import Figure2 from '../assets/tom/2.jpg';
import Figure3 from '../assets/tom/3.jpg';
import Figure4 from '../assets/tom/4.jpg';
import Figure5 from '../assets/tom/5.jpg';
import Figure6 from '../assets/tom/6.jpg';
import Figure7 from '../assets/tom/7.jpg';
import Figure8 from '../assets/tom/8.jpg';
import Figure9 from '../assets/tom/9.jpg';
import Figure10 from '../assets/tom/10.jpg';
import Figure11 from '../assets/tom/11.jpg';
import Figure12 from '../assets/tom/12.jpg';
import Figure13 from '../assets/tom/13.jpg';
import Figure14 from '../assets/tom/14.jpg';
import Figure15 from '../assets/tom/15.jpg';
import Figure16 from '../assets/tom/16.jpg';
import Figure17 from '../assets/tom/17.jpg';
import Figure18 from '../assets/tom/18.jpg';
import '../styles/tom.scss';
import BackButton from '../snippets/BackButton';
import Loading from '../snippets/Loading';
import Intro from '../snippets/Intro';

function Tom() {
  return (
    <div className="tom" style={{height:window.innerHeight+'px'}}>
      <BackButton />
      <Loading />
      <Intro
				name= "톰앤허클베리"
				title= "- 큰 숲과 개구리"
				artistIntro= {`
          톰앤허클베리는 도시 모험을 좋아한다. 모험 결과를 토대로 새 지도를 만든다.<br />
          Tom & Huckleberry like the city adventure. They create a new map based on the results of the adventure.
        `}
				projectDescription= {`
          “대림동 어느 식당은 식당 앞 유리 수조에 개구리 몇십 마리를 넣어 놓는다. 우리가 개구리를 자주 먹지는 않지. 그래도 큰 숲은 한국에 있다. (...) 이곳에서 사진을 찍는 사람들을 잠시 생각해 본다. 그들은 어떤 표정을 지을까? 어떻게 보이려고 할까? 우리가 보는 것은 무엇인가? 보는 것이 아니라 보려는 것이라고 써야 알맞을 것이다.”
        `}
			>
			</Intro>
      <div className="tom__about-container">
        <div className="tom__about-txt-container">
          <div className='tom__about-title'>
            <h1 className='tom__about-title-txt'>
              <b>큰 숲과 개구리</b>
            </h1>
            <h2>톰앤허클베리</h2>
            <br />
          </div>
          <div className='tom__img'>
            <img src={Figure0} />
          </div>
          <p className="tom__p">
            대림(大林)은 큰 숲이라는 뜻이다. 
          </p>
          <div className='tom__img'>
            <img src={Figure1} />
          </div>
          <div className='tom__img'>
            <img src={Figure2} />
          </div>
          <div className='tom__img'>
            <img src={Figure3} />
          </div>
          <p className="tom__p">
            2호선과 7호선 환승역인 대림역 12번 출구로 나오면 바로 대림2동이다. 장소는 그곳에 머무는 사람들을 재현한다. 도림로38길부터 디지털로37길까지 이어지는 길을 따라 걸으면 한국어보다 중국어, 혹은 연변 사투리를 더 자주 들을 수 있다. 연변웨딩과 연변냉면, 훠궈와 마라탕, 양꼬치 식당들 사이로 들어가 대림중앙시장이 나오기 전까지의 길은 읽을 수 없는 간판과 무슨 맛일지 짐작할 수 없는 음식들, 향신료들, 식재료들로 가득차 있다. 서울 내 다른 어디보다도 빠른 처리가 가능하다는 행정사들과 여행사들도 다른 번화가에 비해 많은 편이다. CU나 이마트24처럼 우리에게 익숙하고 서울 어디서든 발견할 수 있는 통신사 대리점도 같은 로고에 모두 중국어가 쓰여 있다. 한국적 건물 골조에 덧씌워진 연변의 이름과 색들. 네이버 지도는 이곳을 ‘대림동 차이나타운’이라고 쓰고 있다.
          </p>
          <div className='tom__img'>
            <img src={Figure4} />
          </div>
          <div className='tom__img'>
            <img src={Figure5} />
          </div>
          <div className='tom__img'>
            <img src={Figure6} />
          </div>
          <div className='tom__img'>
            <img src={Figure7} />
          </div>
          <p className="tom__p">
            그러나 대림을 단순히 ‘차이나타운’이라고 하기에는 무리가 있다. 애초에 차이나타운이라는 용어가 가리키는 바가 명확하지 않다. 차이나타운은 누가 구축하는가? 어떻게 구축되는가? 인위적으로  구축되는가, 자연스럽게 구축되는가? 특정 장소에 거주하는 사람들이 대부분 중국인이라고 해도 장소는 중국스럽지 않을 수도 있고, 거주하는 사람들이 전부 한국인이라고 하더라도 장소는 중국스러울 수 있다. 차이나타운이라는 용어는 우리가 중국이라고 생각되는 것을 집약한 장소를 가리키는 데에 더 적합할까, 조금 덜 빨갛고 노랗고 한국스럽더라도 ‘진짜’ 중국인들이 거주하는 지역을 가리키는 데에 더 적합할까? 
          </p>
          <p className="tom__p">
            어쨌든 대림은 연변으로 강제 이주했던 소위 ‘조선족’들이 코리안 드림을 안고 한국으로 역이주한 결과다. 이들이 한국으로 역이주하고 본래 주로 머물던 곳은 주거 비용이 저렴한 가리봉동이었으나, 재개발로 인해 현재는 대부분이 대림으로 이사했다고 한다. 대림도 마찬가지로 주거 비용이 저렴한 지역이다. 이들의 이주에는 1990년대 이후 한국인 노동자의 임금 상승과 3D 업종 및 단순 노동 기피 현상이 맞물리는 지점이 있을 것이다. 그 결과 현재 대림동 주거 인원 중 90%는 외국인이다.
          </p>
          <div className='tom__img'>
            <img src={Figure8} />
          </div>
          <div className='tom__img'>
            <img src={Figure9} />
          </div>
          <div className='tom__img'>
            <img src={Figure10} />
          </div>
          <p className="tom__p">
            우리가 이들의 존재를 문화적으로 감각하기 시작한 것은 2010년대부터이다. 대중 영화가 이들의 존재를 감각하는 데에 일조했다는 사실을 부정할 이는 없을 것이다. &lt;황해&gt;(2010)부터 &lt;신세계&gt;(2013), &lt;청년경찰&gt;(2017), &lt;범죄도시&gt;(2017)에 이르기까지, 조선족이 우리의 적으로 등장하는 영화들은 연변 사투리가 가미된 말투나 단어 사용에서의 이질적임을 특징으로 살려 일종의 밈이 되었다. &lt;미씽: 사라진 여자&gt;(2016)나 &lt;우상&gt;(2019)처럼 조선족 여성이 등장하는 모든 영화를 포괄해서 생각해 볼 때 미디어 내에서 이들의 공통된 특성 중 하나는 생존을 위해서 불결함이든 잔인함이든 비인간적인 것이든 어떤 것이라도 불사하는 모습으로 그려진다는 것이다. 그들은 우리의 헌법보다 개인의 생존을 더욱 중요하게 생각하는 것처럼 보인다. 이 재현은 감독을 포함한 해당 미디어 제작자의 의도와는 전혀 상관없이 혐오로 이어진 듯하다.
          </p>
          <p className="tom__p">
            조선족에 대한 분노는 조선족 여성이 한복을 입고 개막식에 등장했던 베이징동계올림픽에서도 표출되었다. 비판의 주된 내용은 중국이 한국의 전통적이고 고유한 문화를 그들의 것으로 편입하고 흡수하려 시도한다는 것이었다. 이는 쇼트트랙의 실격 판정 논란, 한복韓服과 한푸漢服, 김치와 파오차이 논란, 동북공정, 2019-2020 홍콩 민주화 운동, 코로나19, 인신매매, 보이스피싱, ’중국인은 무릎을 꿇지 않는다’며 새해 큰절을 하지 않는 케이팝 아이돌 중 중국인 멤버들, ‘알리바바에서 사기 당하지 않고 제품 안전히 소싱하는 방법’ 등과 같은 구체적인 다른 현상들이 모두 집약된 분노이기도 했다. 실제 일어났던 범죄 사건들이 이 혐오를 부추긴 것도 사실이다. 이런 모습은 우리가 보고 싶은 모습일까, 그들이 보이고 싶은 모습일까, 아니면 조금의 가미도 없는 리얼리티일까, 완전히 픽션일까. 어느 쪽이든 상관없다. 단, 미디어와 재현, 이미지가 현실을 앞질러 간다는 것만은 분명하다.
          </p>
          <div className='tom__img'>
            <img src={Figure11} />
          </div>
          <div className='tom__img'>
            <img src={Figure12} />
          </div>
          <p className="tom__p">
            그런데 비슷하게 해외로 강제 이주되었다가 한국으로 역이주한 고려인 케이스와 비교해보자면 조선족에 대한 혐오 감정은 조선족 자체에 대한 혐오 감정이라기보다 중국을 향한 혐오 감정에 가까운 것이 드러난다. 심지어 이 혐오 감정은 괘씸함에 가깝다. 둘 모두 한국의 동포이며 우리와 비슷한 외모를 가졌고 한국어를 구사할 수 있다. 그러나 조선족은 한국보다 중국의 ‘편’을 들기 때문에, 그들이 그들 스스로를 한국인이라기보다 중국인으로 정체화하기 때문에 혐오의 대상이 된다. 
          </p>
          <p className="tom__p">
            “오늘날 타키야 또는 ‘괴물’의 논리에 따라 신자가 자신의 믿음과 실천을 은폐하여 생존을 도모하는 것은 적의 공동체에 파국적인 결과를 불러온다. (…) 괴물을 쓰러뜨리려면 생존하려는 모든 것을 색출해야 하는데, 왜냐하면 그 생존 의지가 무슨 수를 써서라도 생존하려는 괴물의 경향과 부합하기 때문이다. 미미한 존재자일수록 괴물에게는 오히려 더 이상적인 숙주가 된다. (…) 국가가 지하드인으로 의심되는 사람을 하나씩 제거할 때마다 타키야(괴물)을 실천하는 지하드인은 생존을 위해 더 열심히 노력한다. 이것은 더 강력한 타키야(괴물성)를 초래하는데, 말하자면 괴물성이 새로운 숙주들에게 더 많이 확산되는 동시에 토착 민간인 숙주가 지하드인 또는 괴물이 될 잠재력(감염성)이 높아지는 것이다.” 레자 네가레스타니, 『사이클로노피디아』, 윤원화 옮김, 미디어버스, 2021, 192-193쪽. 
          </p>
          <div className='tom__img'>
            <img src={Figure13} />
          </div>
          <p className="tom__p">
            여기서 우리가 주목해야 하는 것은 구분할 수 없음의 문제이다. 조선족 혐오의 근원적인 출발점이라고 할 수 있는 구분할 수 없음의 문제는 소위 ‘사상검증’의 필요성을 야기한다. 의심되는 사람이 입을 열기 전까지, 입을 열어 연변 사투리를 구사하거나 중국을 옹호하는 발언을 하기 전까지, 혹은 새해 큰절을 하는지 하지 않는지 확인하기 전까지 그들은 환대의 대상이었다가도 손쉽게 혐오의 대상으로 전환된다. 한국에 왔으면 한국의 법을 따라야지. 육안으로는 상대의 ‘진정한’ 정체성이 보이지 않는다는 사실은 조선족을 향하는 혐오와 ‘색출’, ‘추방’을 부추긴다. 새롭지 않은 레퍼토리다. 민족은 국가인가? 국가는 민족인가? 한국은 뭐지? 대림동 어느 식당은 식당 앞 유리 수조에 개구리 몇십 마리를 넣어 놓는다. 우리가 개구리를 자주 먹지는 않지. 그래도 큰 숲은 한국에 있다.
          </p>
          <div className='tom__img'>
            <img src={Figure14} />
          </div>
          <div className='tom__img'>
            <img src={Figure15} />
          </div>
          <div className='tom__img'>
            <img src={Figure16} />
          </div>
          <div className='tom__img'>
            <img src={Figure17} />
          </div>
          <p className="tom__p">
            대림동 어느 사진관 앞에 멈춰 선다. 작은 가족들과 큰 가족들, 한복을 입은 가족, 아이들이 웃고 있다. 사진관을 운영한 지 삼십 년이 되었다는 사장님 말처럼 간판이 희미하다. 중국 여권과 한국 여권은 요구하는 사진 규격이 다른데 그걸 모르고 한국 여권 규격으로 촬영해서 다시 촬영을 하러 오는 사람들이 가끔 가다 한두 명씩 있다고 한다. 护照外国人登录证5分完成/婚纱照. 여권, 외국인 등록증 5분 완성, 결혼 사진. 이곳에서 사진을 찍는 사람들을 잠시 생각해 본다. 그들은 어떤 표정을 지을까? 어떻게 보이려고 할까? 우리가 보는 것은 무엇인가? 보는 것이 아니라 보려는 것이라고 써야 알맞을 것이다. 
          </p>
          <div className='tom__img'>
            <img src={Figure18} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tom;