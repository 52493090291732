import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";

const UseGaTracker = ({ history }) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize("UA-222108281-1");
    }
    setInitialized(true);
  }, []);
  
  useEffect(() => {
    if (initialized) {
      history.listen((location, action) => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
      });
    }
  }, [initialized]);

  return <div></div>;
};

export default withRouter(UseGaTracker);