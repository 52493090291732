const Activatable = {
  SELECTORS: {
    toggler: '[data-activatable-toggler]',
    activator: '[data-activatable-activator]',
    deactivator: '[data-activatable-deactivator]',
    toggleableItem: '[data-activatable-toggleable-item]',
    hoverToggler: '[data-activatable-hover-toggler]',
  },
  ATTRIBUTES: {
    toggler: 'data-activatable-toggler',
    activator: 'data-activatable-activator',
    deactivator: 'data-activatable-deactivator',
    toggleableItem: 'data-activatable-toggleable-item',
    hoverToggler: 'data-activatable-hover-toggler',
    active: 'data-active',
  },

  init() {
    const togglers = [].slice.call(
      document.querySelectorAll(Activatable.SELECTORS.toggler),
    );
    const hoverTogglers = [].slice.call(
      document.querySelectorAll(Activatable.SELECTORS.hoverToggler),
    );
    const activators = [].slice.call(
      document.querySelectorAll(Activatable.SELECTORS.activator),
    );
    const deactivators = [].slice.call(
      document.querySelectorAll(Activatable.SELECTORS.deactivator),
    );
    const toggleableItems = [].slice.call(
      document.querySelectorAll(Activatable.SELECTORS.toggleableItem),
    );

    Activatable.bindEventListeners(
      togglers,
      hoverTogglers,
      activators,
      deactivators,
      toggleableItems,
    );
  },

  bindEventListeners(
    togglers: HTMLElement[],
    hoverTogglers: HTMLElement[],
    activators: HTMLElement[],
    deactivators: HTMLElement[],
    toggleableItems: HTMLElement[],
  ) {
    togglers.forEach((toggler) => {
      const toggleId = toggler.getAttribute(Activatable.ATTRIBUTES.toggler);

      const currentToggleableItems = toggleableItems.filter(
        (toggleableItem) =>
          toggleableItem.getAttribute(Activatable.ATTRIBUTES.toggleableItem) ===
          toggleId,
      );

      if (!toggleId && !currentToggleableItems.length) return;

      toggler.addEventListener('click', () => {
        if (Activatable.isActive(currentToggleableItems[0])) {
          Activatable.deactivate(currentToggleableItems);
        } else {
          Activatable.activate(currentToggleableItems);
        }
      });
    });

    hoverTogglers.forEach((hoverToggler) => {
      const toggleId = hoverToggler.getAttribute(
        Activatable.ATTRIBUTES.hoverToggler,
      );

      const currentToggleableItems = toggleableItems.filter(
        (toggleableItem) =>
          toggleableItem.getAttribute(Activatable.ATTRIBUTES.toggleableItem) ===
          toggleId,
      );

      if (!toggleId && !currentToggleableItems.length) return;

      hoverToggler.addEventListener('mouseover', () => {
        Activatable.activate(currentToggleableItems);
      });
      hoverToggler.addEventListener('mouseout', () => {
        Activatable.deactivate(currentToggleableItems);
      });
    });

    activators.forEach((activator) => {
      const activatorId = activator.getAttribute(
        Activatable.ATTRIBUTES.activator,
      );
      const currentActivatorItems = toggleableItems.filter(
        (toggleableItem) =>
          toggleableItem.getAttribute(Activatable.ATTRIBUTES.toggleableItem) ===
          activatorId,
      );

      if (!activatorId && !currentActivatorItems.length) return;

      activator.addEventListener('click', () => {
        Activatable.activate(currentActivatorItems);
      });
    });

    deactivators.forEach((deactivator) => {
      const deactivatorIdsAttr = deactivator.getAttribute(
        Activatable.ATTRIBUTES.deactivator,
      );

      if (!deactivatorIdsAttr) return;

      const deactivatorIds = deactivatorIdsAttr
        .split(',')
        .map((id) => id.trim());

      const currentDeactivatorItems = toggleableItems.filter((toggleableItem) =>
        deactivatorIds.includes(
          toggleableItem.getAttribute(Activatable.ATTRIBUTES.toggleableItem)!,
        ),
      );

      if (!currentDeactivatorItems.length) return;

      deactivator.addEventListener('click', () => {
        Activatable.deactivate(currentDeactivatorItems);
      });
    });
  },

  handleDeactivators(
    deactivators: HTMLElement[],
    toggleableItems: HTMLElement[],
  ) {
    deactivators.forEach((deactivator) => {
      const deactivatorIdsAttr = deactivator.getAttribute(
        Activatable.ATTRIBUTES.deactivator,
      );

      if (!deactivatorIdsAttr) return;

      const deactivatorIds = deactivatorIdsAttr
        .split(',')
        .map((id) => id.trim());

      const currentDeactivatorItems = toggleableItems.filter(
        (toggleableItem) => {
          const toggleableItemAttributte = toggleableItem.getAttribute(
            Activatable.ATTRIBUTES.toggleableItem,
          );
          if (toggleableItemAttributte) {
            deactivatorIds.includes(toggleableItemAttributte);
          }
        },
      );

      if (!currentDeactivatorItems.length) return;

      deactivator.addEventListener('click', () => {
        Activatable.deactivate(currentDeactivatorItems);
      });
    });
  },

  isActive(currentToggleableItem: HTMLElement) {
    return currentToggleableItem.hasAttribute(Activatable.ATTRIBUTES.active);
  },

  deactivate(currentToggleableItems: HTMLElement[]) {
    currentToggleableItems.forEach((toggleableItem) => {
      toggleableItem.removeAttribute(Activatable.ATTRIBUTES.active);
    });
  },

  activate(currentToggleableItems: HTMLElement[]) {
    currentToggleableItems.forEach((toggleableItem) => {
      toggleableItem.setAttribute(Activatable.ATTRIBUTES.active, 'true');
    });
  },
};

export default Activatable;
